import React from "react";
import { Link } from "react-router-dom";

function Ceiling() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{ backgroundImage: `url(images/background/5.jpg)` }}
      >
        <div class="auto-container">
          <h2>Ceiling Design</h2>
          <ul class="page-breadcrumb">
            <li>
              <Link to="/">home</Link>
            </li>
            <li>Services</li>
            <li>Ceiling Design</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!--Sidebar Page Container--> */}
      <div class="sidebar-page-container">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Content Side / Services Detail --> */}
            <div class="content-side col-xl-9 col-lg-8 col-md-12 col-sm-12">
              <div class="services-detail">
                <div class="inner-box">
                  <div class="bold-text">
                    The ceiling designs redefine spaces, creating visual
                    interest, enhancing ambiance, and adding a touch of luxury
                    to every room.
                  </div>
                  <div class="text">
                    <p>
                      <strong>The Fifth Wall, Elevated:</strong>
                      We view ceilings as more than mere overhead spaces; they
                      are canvases waiting to be transformed. Our ceiling
                      designs turn the fifth wall into a focal point,
                      contributing to the overall aesthetic and atmosphere of
                      your interiors.
                    </p>
                    <p>
                      <strong>Architectural Ingenuity Above:</strong>
                      Experience architectural ingenuity in every glance upward.
                      Mianzi Designs specializes in creating ceiling designs
                      that showcase creativity and precision. From intricate
                      patterns to minimalist elegance, we tailor designs to
                      complement the unique characteristics of each space.
                    </p>
                    {/* <!-- Two Column --> */}
                    <div class="two-column">
                      <div class="row clearfix">
                        <div class="column col-lg-4 col-md-4 col-sm-12">
                          <div class="image">
                            <img src="images/resource/service-18.jpg" alt="" />
                          </div>
                          <div class="image">
                            <img src="images/resource/service-19.jpg" alt="" />
                          </div>
                          <div class="image">
                            <img
                              src={require("../resources/assets/media/roomdesign1hf.jpg")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="content-column col-lg-8 col-md-8 col-sm-12">
                          <div class="inner-column">
                            <h3>Ambiance Beyond Boundaries</h3>
                            <p>
                              Our ceiling designs go beyond mere aesthetics;
                              they shape the ambiance of your space. Whether you
                              desire a coffered ceiling for classic
                              sophistication or a modern, suspended design for a
                              contemporary flair, Mianzi creates ceilings that
                              set the tone for the entire room.
                            </p>
                            <p>
                              <strong>
                                Innovative Materials, Timeless Designs:
                              </strong>
                              Mianzi Designs embraces innovation in materials to
                              craft ceilings that stand the test of time. From
                              classic tray ceilings to avant-garde suspended
                              installations, our designs showcase a harmonious
                              blend of modernity and timelessness.
                            </p>
                            <p>
                              <strong>Lighting as an Art Form:</strong>
                              Ceiling designs at Mianzi are conceived as
                              integral components of lighting design. We
                              understand the transformative power of lighting,
                              and our designs incorporate innovative lighting
                              solutions that enhance the overall visual impact
                              of your space.
                            </p>
                            <p>
                              <strong>Personalized Ceiling Statements:</strong>
                              Every space is unique, and so are our ceiling
                              designs. Mianzi collaborates closely with clients
                              to understand their vision and lifestyle, creating
                              bespoke ceiling statements that resonate with the
                              personality of each room.
                            </p>
                            <p>
                              <strong>Collaborate for Skyward Elegance:</strong>
                              You are invited you to collaborate in creating
                              skyward elegance. Whether you seek a timeless
                              coffered ceiling for a traditional setting or a
                              contemporary masterpiece for a modern space, our
                              team is ready to turn your ceiling aspirations
                              into reality.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="service-contact-box">
                      Our experienced Designers are ready for you.
                      <br /> <a href="/contact">Contact us</a> to learn more!
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--Sidebar Side--> */}
            <div class="sidebar-side col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <aside class="sidebar">
                {/* <!-- Sidebar Widget / List Widget--> */}
                <div class="sidebar-widget list-widget">
                  {/* <!-- Services List --> */}
                  <ul class="services-list">
                    <li>
                      <Link to="/residential">Residential Interior</Link>
                    </li>
                    <li>
                      <Link to="/commercial-interior">Commercial Interior</Link>
                    </li>
                    <li>
                      <Link to="/office-design">Office Designs</Link>
                    </li>
                    <li>
                      <Link to="/hospitality-design">Hospitality Design</Link>
                    </li>
                    <li>
                      <Link to="/modern-furniture">Modern Furniture</Link>
                    </li>
                    <li>
                      <Link to="/modern-kitchen">Modern Kitchen</Link>
                    </li>
                    <li>
                      <Link to="/wardrobe">Wardrobe</Link>
                    </li>
                    <li class="active">
                      <Link to="/ceiling-design">Ceiling Designs</Link>
                    </li>
                  </ul>
                </div>

                {/* <!-- Sidebar Widget / Contact Widget--> */}
                <div class="sidebar-widget contact-widget">
                  <div
                    class="widget-content"
                    style={{ backgroundImage: `url(images/background/7.jpg)` }}
                  >
                    <h3>Get Free Quote</h3>
                    <Link to="/contact" class="theme-btn btn-style-one">
                      <span class="txt">contact us</span>
                    </Link>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ceiling;
