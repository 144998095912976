import React from "react";
import { Link } from "react-router-dom";

function Blog() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        className="page-title"
        style={{ backgroundImage: `url(images/background/5.jpg)` }}
      >
        <div className="auto-container">
          <h2>Blogs</h2>
          <ul className="page-breadcrumb">
            <li>
              <Link to="/">home</Link>
            </li>
            <li>Blogs</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!--Sidebar Page Container--> */}
      <div className="sidebar-page-container">
        <div className="auto-container">
          <div className="row clearfix">
            {/* <!--Content Side / Blog Classic --> */}
            <div className="content-side col-xl-9 col-lg-8 col-md-12 col-sm-12">
              <div className="blog-classic padding-right">
                {/* <!-- News Block Three--> */}
                <div className="news-block-three">
                  <div className="inner-box">
                    <div className="image-box">
                      <figure className="image">
                        <a href="blog-detail.html">
                          <img src="images/resource/news-4.jpg" alt="" />
                        </a>
                      </figure>
                      <span className="date">9th Jan 2024</span>
                    </div>
                    <div className="lower-content">
                      <div className="post-meta">
                        <ul className="post-info clearfix">
                          <li>
                            <a href="#">By : Sylvia Essendi ~ Founder</a>
                          </li>
                          <li>
                            <a href="#">interior, furniture</a>
                          </li>
                          <li>
                            <a href="#">Comments: 8</a>
                          </li>
                        </ul>
                      </div>
                      <h3>
                        <a href="#">Interiors designs and room tone</a>
                      </h3>
                      <div className="text">
                        Crafting captivating interiors goes beyond arranging
                        furniture; it's an orchestration of colors, textures,
                        and spatial elements that harmonize to set the tone for
                        every room. In our blog, we explore the symbiotic
                        relationship between design and room tone – the
                        emotional resonance that a space evokes. We dissect the
                        psychology of color choices, the impact of lighting on
                        ambiance, and the strategic use of textures to create
                        tactile experiences. Join us as we unravel the secrets
                        of transforming rooms into immersive environments, where
                        each design decision contributes to the overall mood and
                        atmosphere. From cozy and intimate to expansive and
                        invigorating, our blog invites you to discover the art
                        of interior design and the subtle nuances that shape the
                        character of every room...
                      </div>
                      <div className="link-box">
                        <a
                          href="blog-detail.html"
                          className="theme-btn read-more"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- News Block Three--> */}
                <div className="news-block-three">
                  <div className="inner-box">
                    <div className="image-box">
                      <figure className="image">
                        <a href="blog-detail.html">
                          <img src="images/resource/news-5.jpg" alt="" />
                        </a>
                      </figure>
                      <span className="date">9 may 2019</span>
                    </div>
                    <div className="lower-content">
                      <div className="post-meta">
                        <ul className="post-info clearfix">
                          <li>
                            <a href="#">By : Sylvia Essendi ~ Founder</a>
                          </li>
                          <li>
                            <a href="#">interior, furniture</a>
                          </li>
                          <li>
                            <a href="#">Comments: 8</a>
                          </li>
                        </ul>
                      </div>
                      <h3>
                        <a href="#">
                          Advantages of using bamboo for interior designs
                        </a>
                      </h3>
                      <div className="text">
                        Embracing bamboo in interior spaces is not just an
                        aesthetic choice but a sustainable and versatile one.
                        Our blog delves into the inherent qualities of bamboo,
                        such as its rapid renewability, strength, and
                        flexibility, making it an eco-friendly alternative to
                        traditional materials. We discuss how bamboo's natural
                        beauty and diverse applications contribute to creating
                        serene and organic interiors. Beyond its aesthetic
                        appeal, we shed light on the practical benefits,
                        including bamboo's resistance to moisture, durability,
                        and low maintenance requirements. Join us as we unfold
                        the many advantages of incorporating bamboo into
                        interior designs, transforming spaces into eco-conscious
                        havens that seamlessly blend beauty with sustainability.
                        Discover why bamboo is not just a material choice but a
                        design ethos that aligns with our commitment to creating
                        spaces that are not only visually stunning but also
                        environmentally responsible....
                      </div>
                      <div className="link-box">
                        <a
                          href="blog-detail.html"
                          className="theme-btn read-more"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- News Block Three--> */}
                <div className="news-block-three">
                  <div className="inner-box">
                    <div className="image-box">
                      <figure className="image">
                        <a href="blog-detail.html">
                          <img src="images/resource/news-6.jpg" alt="" />
                        </a>
                      </figure>
                      <span className="date">9 may 2019</span>
                    </div>
                    <div className="lower-content">
                      <div className="post-meta">
                        <ul className="post-info clearfix">
                          <li>
                            <a href="blog-detail.html">By : Sylvia ~ Founder</a>
                          </li>
                          <li>
                            <a href="#">Designs, Innovation</a>
                          </li>
                          <li>
                            <a href="#">Comments: 8</a>
                          </li>
                        </ul>
                      </div>
                      <h3>
                        <a href="blog-detail.html">
                          How we make great interiors
                        </a>
                      </h3>
                      <div className="text">
                        Our journey towards great designs encompasses
                        collaborative brainstorming sessions, where creativity
                        knows no bounds. We believe in the power of inspiration
                        from diverse sources, merging architectural principles
                        with innovative thinking. Our team, a blend of
                        visionaries and craftsmen, works cohesively to transform
                        ideas into tangible designs. We leverage cutting-edge
                        technology, stay abreast of design trends, and remain
                        committed to sustainability. At Mianzi Designs, the
                        recipe for great designs is a fusion of passion,
                        precision, and a relentless pursuit of excellence. Join
                        us on the blog as we unravel the stories and
                        methodologies behind the designs that redefine spaces
                        and leave a lasting impression.
                      </div>
                      <div className="link-box">
                        <a
                          href="blog-detail.html"
                          className="theme-btn read-more"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- News Block Three--> */}
                <div className="news-block-three">
                  <div className="inner-box">
                    <div className="image-box">
                      <figure className="image">
                        <a href="blog-detail.html">
                          <img src="images/resource/news-7.jpg" alt="" />
                        </a>
                      </figure>
                      <span className="date">9 may 2019</span>
                    </div>
                    <div className="lower-content">
                      <div className="post-meta">
                        <ul className="post-info clearfix">
                          <li>
                            <a href="blog-detail.html">
                              By : Sylvia Essendi ~ Founder
                            </a>
                          </li>
                          <li>
                            <a href="blog-detail.html">interior, Designs</a>
                          </li>
                          <li>
                            <a href="blog-detail.html">Comments: 0</a>
                          </li>
                        </ul>
                      </div>
                      <h3>
                        <a href="blog-detail.html">
                          Why invest on Great interiors
                        </a>
                      </h3>
                      <div className="text">
                        Beyond aesthetics, great interiors serve as a strategic
                        investment that redefines the way we experience and
                        interact with spaces. Our blog explores how thoughtfully
                        designed interiors enhance overall well-being by
                        creating environments that inspire, motivate, and foster
                        positive energy. We discuss the impact of well-designed
                        spaces on mood, productivity, and even physical health.
                        Investing in great interiors is an investment in the
                        quality of life, as we uncover the transformative power
                        of a harmonious and visually pleasing environment.
                        Furthermore, we explore how outstanding interiors can
                        significantly increase the value of a property, making
                        it a wise financial investment. Join us on the blog as
                        we unveil the myriad reasons why dedicating resources to
                        creating exceptional interiors is not just a choice but
                        a strategic decision with long-term benefits for both
                        individuals and businesses alike....
                      </div>
                      <div className="link-box">
                        <a
                          href="blog-detail.html"
                          className="theme-btn read-more"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--Styled Pagination--> */}
              <ul className="styled-pagination">
                <li>
                  <a href="/" className="active">
                    1
                  </a>
                </li>
                <li>
                  <a href="/">2</a>
                </li>
                <li>
                  <a href="/">
                    <span className="fa fa-angle-right"></span>
                  </a>
                </li>
              </ul>
              {/* <!--End Styled Pagination--> */}
            </div>

            {/* <!--Sidebar Side--> */}
            <div className="sidebar-side col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <aside className="sidebar">
                {/* <!-- Search --> */}
                <div className="sidebar-widget search-box">
                  <form method="post" action="templateshub.net">
                    <div className="form-group">
                      <input
                        type="search"
                        name="search-field"
                        value=""
                        placeholder="Enter Search Keywords"
                        required
                      />
                      <button type="submit">
                        <span className="icon fa fa-search"></span>
                      </button>
                    </div>
                  </form>
                </div>

                {/* <!--Blog Category Widget--> */}
                <div className="sidebar-widget sidebar-blog-category">
                  <div className="sidebar-title">
                    <h2>Categories</h2>
                  </div>
                  <ul className="cat-list">
                    <li>
                      <a href="/">Modular Kitchen</a>
                    </li>
                    <li>
                      <a href="/">Lighting Effect</a>
                    </li>
                    <li>
                      <a href="/">Interior Tips</a>
                    </li>
                    <li>
                      <a href="/">Modern Wardrobe</a>
                    </li>
                    <li>
                      <a href="/">Modern Furniture</a>
                    </li>
                    <li>
                      <a href="/">Wooden Interior</a>
                    </li>
                  </ul>
                </div>

                {/* <!-- Popular Posts --> */}
                <div className="sidebar-widget popular-posts">
                  <div className="sidebar-title">
                    <h2>Recent News</h2>
                  </div>

                  <article className="post">
                    <figure className="post-thumb">
                      <a href="/blog-details/like">
                        <img src="images/resource/post-thumb-1.jpg" alt="" />
                      </a>
                    </figure>
                    <div className="text">
                      <a href="blog-detail/post">
                        Hanging fruit to identify a ballpark value added ...
                      </a>
                    </div>
                    <div className="post-info">12 April. 2021</div>
                  </article>

                  <article className="post">
                    <figure className="post-thumb">
                      <a href="blog-detail.html">
                        <img src="images/resource/post-thumb-2.jpg" alt="" />
                      </a>
                    </figure>
                    <div className="text">
                      <a href="blog-detail/">
                        Organically grow the holistic world view ...
                      </a>
                    </div>
                    <div className="post-info">10 March. 2022</div>
                  </article>

                  <article className="post">
                    <figure className="post-thumb">
                      <a href="blog-detail/">
                        <img src="images/resource/post-thumb-3.jpg" alt="" />
                      </a>
                    </figure>
                    <div className="text">
                      <a href="blog-detail.html">
                        Bring to the table in the win-win survival ...
                      </a>
                    </div>
                    <div className="post-info">20 May. 2020</div>
                  </article>

                  <article className="post">
                    <figure className="post-thumb">
                      <a href="/blog-detail/like">
                        <img src="images/resource/post-thumb-4.jpg" alt="" />
                      </a>
                    </figure>
                    <div className="text">
                      <a href="/blog-detail/post">
                        Override the digital divide with additional ...
                      </a>
                    </div>
                    <div className="post-info">03 October. 2022</div>
                  </article>
                </div>

                {/* <!-- Popular Tags --> */}
                <div className="sidebar-widget popular-tags">
                  <div className="sidebar-title">
                    <h2>Tags</h2>
                  </div>
                  <a href="1">Interior Tips</a>
                  <a href="2">Lighting</a>
                  <a href="3">Designs</a>
                  <a href="4">Vintage Look</a>
                  <a href="5">Kitchens</a>
                  <a href="6">Kitchens</a>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
