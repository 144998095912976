import React, { useEffect, useState } from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import testimonybg from "../resources/gallery/1.jpg";
import callaction from "../resources/background/1.jpg";
import slide from "../resources/background/1.jpg";
import slide1 from "../resources/background/2.jpg";
import slide2 from "../resources/background/3.jpg";
import vidsec from "../resources/resource/video-img.jpg";
import { Link } from "react-router-dom";

function Home() {
  const [items, setItems] = useState(1);
  const screenWidth = window.innerWidth;
  const handleResize = () => {
    if (screenWidth <= 648) {
      setItems(1);
    } else if (screenWidth > 648 && screenWidth <= 1024) {
      setItems(2);
    } else {
      setItems(3);
    }
  };
  useEffect(() => {
    handleResize();
  }, []);
  const options = {
    items: 1, // Show one item per slide
    loop: true,
    margin: 0, // No margin between slides
    autoplay: true,
    responsiveClass: true,
    animateOut: "fadeOut",
  };

  return (
    <div>
      {/* <!-- Main Slider --> */}
      <section className="main-slider">
        <div className="slider-box">
          {/* <!-- Banner Carousel --> */}
          <OwlCarousel
            className="banner-carousel owl-theme owl-carousel"
            {...options}
            nav={true}
          >
            {/* <!-- Slide --> */}
            <div className="item slide">
              <div
                className="image-layer"
                style={{
                  backgroundImage: `url(${slide})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className="auto-container">
                <div className="content">
                  <h2>
                    Elevating Dreams, <br /> Crafting Reality.
                  </h2>
                  <div className="text">
                    We turn dreams into tangible reality through innovative and
                    thoughtful architectural designs. Our team crafts spaces
                    that resonate with your aspirations and elevate the way you
                    live and work.
                  </div>
                  <div className="btns-box">
                    <Link to="/about" className="theme-btn btn-style-one">
                      <span className="txt">Know more</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Slide --> */}
            <div className="slide">
              <div
                className="image-layer"
                style={{
                  backgroundImage: `url(${slide1})`,
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="auto-container">
                <div className="content">
                  <h2>
                    Architectural Excellence, <br /> Every Design Tells a Story.
                  </h2>
                  <div className="text">
                    We narrate compelling stories through our designs. Each
                    architectural masterpiece reflects a unique tale, blending
                    form and function to achieve unparalleled excellence.
                  </div>
                  <div className="btns-box">
                    <Link to="/about" className="theme-btn btn-style-one">
                      <span className="txt">Know more</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Slide --> */}
            <div className="slide">
              <div
                className="image-layer"
                style={{
                  backgroundImage: `url(${slide2})`,
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="auto-container">
                <div className="content">
                  <h2>
                    Inspired Designs, <br /> Exceptional Living.
                  </h2>
                  <div className="text">
                    Our designs are an embodiment of inspiration. Mianzi Designs
                    creates spaces that inspire exceptional living, where every
                    detail is meticulously crafted to enhance your lifestyle.
                  </div>
                  <div className="btns-box">
                    <Link to="/about" className="theme-btn btn-style-one">
                      <span className="txt grid-cols-5">Know more</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
      {/* <!-- End Banner Section --> */}

      {/* <!-- Services Section --> */}
      <section className="services-section">
        <div className="auto-container">
          {/* <!-- Title Box --> */}
          <div className="title-box">
            <h2>
              Awesome designs for you, <br /> explore creativity.
            </h2>
          </div>

          <div className="row clearfix">
            {/* <!-- Service Block --> */}
            <div className="service-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <Link to="/residential">
                    <img
                      src={require("../resources/resource/service-1.jpg")}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to="/residential">Residential Designs</Link>
                  </h3>
                  <div className="text">
                    Make your dream home a reality with Mianzi's residential
                    designs. We specialize in creating personalized living
                    spaces that reflect your lifestyle and aspirations.
                  </div>
                  <Link to="/residential" className="read-more">
                    Read more
                  </Link>
                </div>
              </div>
            </div>

            {/* <!-- Service Block --> */}
            <div className="service-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <Link href="office-interior.html">
                    <img
                      src={require("../resources/resource/service-2.jpg")}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to="/office-design">Office Designs</Link>
                  </h3>
                  <div className="text">
                    Elevate your workspace with Mianzi's visionary office
                    designs. We seamlessly blend functionality with aesthetics,
                    creating environments that inspire productivity and
                    innovation.
                  </div>
                  <Link to="/office-design" className="read-more">
                    Read more
                  </Link>
                </div>
              </div>
            </div>

            {/* <!-- Service Block --> */}
            <div className="service-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInRight"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <Link to="/commercial-interior">
                    <img
                      src={require("../resources/resource/service-3.jpg")}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to="/commercial-interior">Commercial Designs</Link>
                  </h3>
                  <div className="text">
                    Mianzi redefines commercial spaces with designs that reflect
                    your brand identity. Our commercial designs merge aesthetics
                    with practicality to create dynamic and inspiring business
                    environments.
                  </div>
                  <Link href="commercial-interior.html" className="read-more">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}

      {/* <!-- Services Section Two --> */}
      <section className="services-section-two">
        <div className="auto-container">
          {/* <!-- Sec Title --> */}
          <div className="sec-title">
            <h2>Our Services</h2>
            <div className="text">
              We work with a team of young people whom we have empowered through
              support from our partners. We hope that as we grow, we shall
              create more employment for young people and women.
            </div>
          </div>

          <div className="row clearfix">
            {/* <!-- Service Block --> */}
            <div className="service-block-two col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="content">
                  <div className="icon-box">
                    <span className="icon flaticon-desk-1"></span>
                  </div>
                  <h3>
                    <Link to="/office-design">Office Designs</Link>
                  </h3>
                  <div className="text">
                    Elevate your workspace with Mianzi's visionary office
                    designs. We seamlessly blend functionality with aesthetics,
                    creating environments that inspire productivity and
                    innovation.
                  </div>
                  <Link to="/office-design" className="read-more">
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            {/* <!-- Service Block --> */}
            <div className="service-block-two col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="content">
                  <div className="icon-box">
                    <span className="icon flaticon-house-1"></span>
                  </div>
                  <h3>
                    <Link to="/residential">House Designs</Link>
                  </h3>
                  <div className="text">
                    Experience the art of living with Mianzi's house designs.
                    Our personalized approach transforms houses into homes,
                    combining aesthetics with functionality for a truly
                    harmonious living experience.
                  </div>
                  <Link href="/residential" className="read-more">
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            {/* <!-- Service Block --> */}
            <div className="service-block-two col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className="content">
                  <div className="icon-box">
                    <span className="icon flaticon-shop"></span>
                  </div>
                  <h3>
                    <Link to="/commercial-interior">Restaurant Designs</Link>
                  </h3>
                  <div className="text">
                    Elevate the dining experience with Mianzi's restaurant
                    designs. Our innovative concepts transform spaces into
                    culinary adventures, seamlessly blending ambiance with
                    culinary excellence.
                  </div>
                  <Link to="/commercial-interior" className="read-more">
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            {/* <!-- Service Block --> */}
            <div className="service-block-two col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInRight"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="content">
                  <div className="icon-box">
                    <span className="icon flaticon-hospital"></span>
                  </div>
                  <h3>
                    <Link to="commercial-interior">Hospital Designs</Link>
                  </h3>
                  <div className="text">
                    Redefine healthcare environments with Mianzi's hospital
                    designs. Our thoughtful approach combines functionality with
                    compassion, creating spaces that promote healing and
                    well-being.
                  </div>
                  <Link to="commercial-interior" className="read-more">
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            {/* <!-- Service Block --> */}
            <div className="service-block-two col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInRight"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="content">
                  <div className="icon-box">
                    <span className="icon flaticon-apartment-1"></span>
                  </div>
                  <h3>
                    <Link href="office-interior.html">Apartment Interior</Link>
                  </h3>
                  <div className="text">
                    Elevate apartment living with Mianzi's designs. Our
                    apartment designs balance style with practicality, offering
                    residents a harmonious and comfortable urban lifestyle.
                  </div>
                  <Link to="commercial-interior" className="read-more">
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            {/* <!-- Service Block --> */}
            <div className="service-block-two col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInRight"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className="content">
                  <div className="icon-box">
                    <span className="icon flaticon-hotel"></span>
                  </div>
                  <h3>
                    <Link to="office-design">Hospitality Design</Link>
                  </h3>
                  <div className="text">
                    Experience the art of hospitality through Mianzi's designs.
                    Our hospitality designs create immersive guest experiences,
                    balancing luxury and functionality.
                  </div>
                  <Link to="office-design" className="read-more">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section Two --> */}

      {/* <!-- Project Section --> */}
      <section className="project-section">
        <div className="auto-container">
          {/* <!-- Title Box --> */}
          <div className="title-box">
            <h2>Our Latest Projects</h2>
          </div>
        </div>

        <div className="outer-container">
          {/* <!--Isotope Galery--> */}
          <div className="sortable-masonry">
            {/* <!--Filter--> */}
            <div className="filters clearfix">
              <ul className="filter-tabs filter-btns text-center clearfix">
                <li
                  className="active filter"
                  data-role="button"
                  data-filter=".all"
                >
                  All Projects
                </li>
                <li
                  className="filter"
                  data-role="button"
                  data-filter=".residential"
                >
                  Residential
                </li>
                <li
                  className="filter"
                  data-role="button"
                  data-filter=".commercial"
                >
                  Commercial
                </li>
                <li
                  className="filter"
                  data-role="button"
                  data-filter=".hospital"
                >
                  Hospitality
                </li>
                <li className="filter" data-role="button" data-filter=".office">
                  Office
                </li>
              </ul>
            </div>

            <div className="items-container row clearfix">
              {/* <!-- Gallery Item --> */}
              <div className="gallery-item large-block masonry-item all hospital commercial">
                <div className="inner-box">
                  <figure className="image-box">
                    <img src={require("../resources/gallery/1.jpg")} alt="" />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth.html">
                              Modern Bedroom
                            </Link>
                          </h3>
                          <a
                            href={require("../resources/gallery/1.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link href="projects-fullwidth.html" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>

              {/* <!-- Gallery Item --> */}
              <div className="gallery-item small-block masonry-item all hospital commercial">
                <div className="inner-box">
                  <figure className="image-box">
                    <img src={require("../resources/gallery/2.jpg")} alt="" />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth.html">
                              Modern Kitchen
                            </Link>
                          </h3>
                          <a
                            href={require("../resources/gallery/2.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link href="projects-fullwidth.html" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>

              {/* <!-- Gallery Item --> */}
              <div className="gallery-item small-block masonry-item all residential office commercial">
                <div className="inner-box">
                  <figure className="image-box">
                    <img src={require("../resources/gallery/3.jpg")} alt="" />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth.html">
                              Modern Living space
                            </Link>
                          </h3>
                          <a
                            href={require("../resources/gallery/3.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link href="projects-fullwidth.html" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>
              {/* <!-- Gallery Item --> */}
              <div className="gallery-item small-block masonry-item all residential office">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("../resources/assets/projects/IMG-20240218-WA0076.jpg")}
                      alt=""
                    />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth.html">
                              Proposed Residential House in Rimpa
                            </Link>
                          </h3>
                          <a
                            href={require("../resources/assets/projects/IMG-20240218-WA0076.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link href="projects-fullwidth.html" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>

              {/* Gallery Item */}
              <div className="gallery-item small-block masonry-item all residential">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("../resources/assets/projects/residentialrongai.jpg")}
                      alt=""
                    />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth.html">
                              Proposed Residential House in Rongai
                            </Link>
                          </h3>
                          <a
                            href={require("../resources/assets/projects/residentialrongai.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link href="projects-fullwidth.html" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>

              {/* Gallery Item */}
              <div className="gallery-item small-block masonry-item all residential">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("../resources/assets/projects/ekegondihouse.jpg")}
                      alt=""
                    />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth.html">
                              Ekegondi House
                            </Link>
                          </h3>
                          <a
                            href={require("../resources/assets/projects/ekegondihouse.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link href="projects-fullwidth.html" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>

              {/* <!-- Gallery Item --> */}
              <div className="gallery-item small-block masonry-item all residential office commercial">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("../resources/assets/projects/officeblockkaren.jpg")}
                      alt=""
                    />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth.html">
                              Proposed office block Karen
                            </Link>
                          </h3>
                          <a
                            href={require("../resources/assets/projects/officeblockkaren.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link to="/projects" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>

              {/* <!-- Gallery Item --> */}
              <div className="gallery-item small-block masonry-item all residential office commercial">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("../resources/assets/projects/officelambwevalley.jpg")}
                      alt=""
                    />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth.html">
                              Proposed Office Lambwe Valley
                            </Link>
                          </h3>
                          <a
                            href={require("../resources/assets/projects/officelambwevalley.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link to="/projects" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>

              {/* <!-- Gallery Item --> */}
              <div className="gallery-item small-block masonry-item all commercial">
                <div className="inner-box">
                  <figure className="image-box">
                    <img src={require("../resources/gallery/4.jpg")} alt="" />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth.html">
                              Living Room
                            </Link>
                          </h3>
                          <a
                            href={require("../resources/gallery/4.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link href="projects-fullwidth.html" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>

              {/* <!-- Gallery Item --> */}
              <div className="gallery-item large-block masonry-item all hospital office residential">
                <div className="inner-box">
                  <figure className="image-box">
                    <img src={require("../resources/gallery/7.jpg")} alt="" />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth">Living Room</Link>
                          </h3>
                          <a
                            href={require("../resources/gallery/7.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link href="projects-fullwidth.html" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>

              {/* <!-- Gallery Item --> */}
              <div className="gallery-item small-block masonry-item all residential">
                <div className="inner-box">
                  <figure className="image-box">
                    <img src={require("../resources/gallery/5.jpg")} alt="" />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth.html">
                              Chill rooms
                            </Link>
                          </h3>
                          <a
                            href={require("../resources/gallery/5.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link href="projects-fullwidth.html" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>

              {/* <!-- Gallery Item --> */}
              <div className="gallery-item small-block masonry-item all residential">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("../resources/assets/media/livingroom.jpg")}
                      alt=""
                    />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth.html">
                              Chill rooms
                            </Link>
                          </h3>
                          <a
                            href={require("../resources/assets/media/livingroom.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link href="projects-fullwidth.html" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>

              {/* <!-- Gallery Item --> */}
              <div className="gallery-item small-block masonry-item all residential">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("../resources/assets/media/project.jpg")}
                      alt=""
                    />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth.html">Project</Link>
                          </h3>
                          <a
                            href={require("../resources/assets/media/project.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link href="projects-fullwidth.html" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>

              {/* <!-- Gallery Item --> */}
              <div className="gallery-item small-block masonry-item all hospital office">
                <div className="inner-box">
                  <figure className="image-box">
                    <img src={require("../resources/gallery/6.jpg")} alt="" />
                    {/* <!--Overlay Box--> */}
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <h3>
                            <Link href="projects-fullwidth.html">
                              Organize bookshelf
                            </Link>
                          </h3>
                          <a
                            href={require("../resources/gallery/6.jpg")}
                            data-fancybox="gallery-1"
                            data-caption=""
                            className="link"
                          >
                            <span className="icon flaticon-magnifying-glass-1"></span>
                          </a>
                          <Link to="/projects" className="link">
                            <span className="icon flaticon-unlink"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- More Projects --> */}
          <div className="more-projects">
            <a href="/projects" className="projects">
              All Projects
            </a>
          </div>
        </div>
      </section>
      {/* <!-- End Project Section --> */}

      {/* <!-- Fluid Section One --> */}
      <section className="fluid-section-one">
        <div className="outer-container clearfix">
          {/* <!--Content Column--> */}
          <div className="content-column">
            <div className="content-box">
              <h2>Seamless Design</h2>
              <div className="text">
                Discover the art of continuity with Mianzi's Seamless Designs.
                We specialize in creating spaces where every element flows
                effortlessly, blurring the lines between form and function. Our
                commitment to seamless design ensures a harmonious visual
                journey, where transitions are subtle, and the overall aesthetic
                is cohesive. Whether it's the flow between rooms, the
                integration of natural elements, or the unification of diverse
                styles, Mianzi Designs brings a sense of continuity to every
                project, creating spaces that feel effortlessly connected and
                inviting.
              </div>
              {/* <ul className="list-style-one">
                <li>Whole Home Interior</li>
                <li>Modular Kitchen and Wardrobe</li>
                <li>Furniture, Decore and more</li>
                <li>On Site Expertiset</li>
              </ul> */}
              <div className="bold-text">
                Design your home, right here at Mianzi Designs <br />{" "}
                <Link to="/contact">Talk to a designer</Link>
              </div>
            </div>
          </div>

          {/* <!--Image Column--> */}
          <div
            className="image-column"
            style={{
              backgroundImage: `url(${vidsec}))`,
            }}
          >
            <div className="inner-column">
              <div className="image">
                <img
                  src={require("../resources/resource/video-img.jpg")}
                  alt=""
                />
              </div>
              <a
                href="https://youtu.be/y7_Spedf2BI"
                className="overlay-link lightbox-image"
              >
                <div className="icon-box">
                  <span className="icon flaticon-play-button"></span>
                  <i className="ripple"></i>
                </div>
              </a>
            </div>
          </div>
          {/* <!--End Image Column--> */}
        </div>
      </section>

      {/* <!-- Testimonial Section --> */}
      <section className="testimonial-section">
        <div className="auto-container">
          {/* <!-- Sec Title --> */}
          <div className="sec-title-two centered">
            <h2>What our clients say</h2>
            <div className="title-text">From our satisfied clients</div>
          </div>

          <OwlCarousel
            loop={true}
            autoPlay={true}
            margin={10}
            items={items}
            className="testimonial-carousel owl-carousel owl-theme"
          >
            {/* <!-- Testimonial Block --> */}
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="content">
                  <div className="image-outer">
                    <div className="image">
                      <img
                        src="https://img.icons8.com/parakeet-line/48/user.png"
                        alt=""
                        style={{
                          height: "50px",
                          borderRadius: "50px",
                          width: "50px",
                        }}
                      />
                    </div>
                  </div>
                  <h3>John.M</h3>
                  <div className="title">
                    Mianzi Designs exceeded our expectations in designing our
                    dream house.
                  </div>
                  <div className="text">
                    Their team understood our lifestyle and incorporated it into
                    every detail. The result is a home that feels uniquely ours.
                    Professional, creative, and a delight to work with!
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="content">
                  <div className="image-outer">
                    <div className="image">
                      <img
                        src="https://img.icons8.com/parakeet-line/48/user.png"
                        alt=""
                        style={{
                          height: "50px",
                          borderRadius: "50px",
                          width: "50px",
                        }}
                      />
                    </div>
                  </div>
                  <h3>Mwangi .R</h3>
                  <div className="title">
                    Living in a Mianzi-designed apartment is a joy.
                  </div>
                  <div className="text">
                    The thoughtful layout maximizes space, and the design
                    details make it feel like a true home. The team was
                    responsive to our needs, and the result is an apartment that
                    reflects our personality and lifestyle. Thank you for
                    creating our urban sanctuary!
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="content">
                  <div className="image-outer">
                    <div className="image">
                      <img
                        src="https://img.icons8.com/parakeet-line/48/user.png"
                        alt=""
                        style={{
                          height: "50px",
                          borderRadius: "50px",
                          width: "50px",
                        }}
                      />
                    </div>
                  </div>
                  <h3>Rebecca .L</h3>
                  <div className="title">
                    Mianzi Designs transformed our restaurant into a true
                    culinary haven.
                  </div>
                  <div className="text">
                    The ambiance they created perfectly complements our menu.
                    Guests often compliment the design, and we've seen a
                    significant increase in customer satisfaction. Truly
                    remarkable work!
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="content">
                  <div className="image-outer">
                    <div className="image">
                      <img
                        src="https://img.icons8.com/parakeet-line/48/user.png"
                        alt=""
                        style={{
                          height: "50px",
                          borderRadius: "50px",
                          width: "50px",
                        }}
                      />
                    </div>
                  </div>
                  <h3>Sarah .H</h3>
                  <div className="title">
                    Working with Mianzi Designs was an absolute pleasure!
                  </div>
                  <div className="text">
                    They turned my vision for a modern kitchen into a reality.
                    The attention to detail and seamless execution were
                    impressive. I now have a kitchen that's not just functional
                    but also a statement of contemporary elegance.
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
      {/* <!-- End Testimonial Section --> */}

      {/* <!-- Featured Section --> */}
      <section
        className="featured-section"
        style={{ backgroundImage: `url(${testimonybg})` }}
      >
        <div className="auto-container">
          {/* <!-- Title Box --> */}
          <div className="title-box">
            <h2>Our Work Process</h2>
          </div>

          <div className="row clearfix">
            {/* <!-- Feature Block --> */}
            <div className="feature-block col-lg-3 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="icon-outer">
                  <div className="icon-box">
                    <span className="icon flaticon-hand-shake"></span>
                  </div>
                  <div className="feature-number">1</div>
                </div>
                <div className="lower-content">
                  <h3>
                    <a href="#">Meet Customers</a>
                  </h3>
                  <div className="text">
                    Initiate the process by engaging in collaborative
                    discussions with clients, aiming to understand their vision,
                    requirements, and lifestyle to establish a strong foundation
                    for the project.
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div className="feature-block col-lg-3 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="250ms"
                data-wow-duration="1500ms"
              >
                <div className="icon-outer">
                  <div className="icon-box">
                    <span className="icon flaticon-answer"></span>
                  </div>
                  <div className="feature-number">2</div>
                </div>
                <div className="lower-content">
                  <h3>
                    <a href="#">Design Discussion</a>
                  </h3>
                  <div className="text">
                    Conduct in-depth design discussions, fostering creative
                    brainstorming to shape a design concept that aligns
                    seamlessly with the client's vision, preferences, and
                    aspirations.
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div className="feature-block col-lg-3 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="500ms"
                data-wow-duration="1500ms"
              >
                <div className="icon-outer">
                  <div className="icon-box">
                    <span className="icon flaticon-sketch"></span>
                  </div>
                  <div className="feature-number">3</div>
                </div>
                <div className="lower-content">
                  <h3>
                    <a href="#">Drafting Design</a>
                  </h3>
                  <div className="text">
                    Transforms conceptual ideas into tangible drafts and
                    blueprints, utilizing advanced tools to create detailed
                    plans, layouts, and aesthetic elements that serve as the
                    foundation for the project's implementation.
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div className="feature-block col-lg-3 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="750ms"
                data-wow-duration="1500ms"
              >
                <div className="icon-outer">
                  <div className="icon-box">
                    <span className="icon flaticon-house-1"></span>
                  </div>
                  <div className="feature-number">4</div>
                </div>
                <div className="lower-content">
                  <h3>
                    <a href="#">Implementation</a>
                  </h3>
                  <div className="text">
                    The vision comes to life here, we take charge of the
                    construction and execution, coordinating with contractors
                    and ensuring quality craftsmanship to deliver a finished
                    product that exceeds client expectations.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Featured Section --> */}

      {/* <!-- News Section --> */}
      <section className="news-section">
        <div className="auto-container">
          {/* <!-- Sec Title --> */}
          <div className="sec-title">
            <h2>Need a design fix? Read our Stories.</h2>
            <div className="text">
              Stay updated with latest trends, inspiration, expert tips, DIYs
              and more
            </div>
          </div>

          <div className="row clearfix">
            {/* <!-- News Block --> */}
            <div className="news-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInRight"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <a href="/blogs">
                    <img
                      src={require("../resources/resource/news-1.jpg")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="lower-content">
                  <ul className="post-meta">
                    <li>
                      By <span>Michale</span>
                    </li>
                    <li>Modern Kitchen</li>
                  </ul>
                  <h3>
                    <a href="/blogs">
                      Best ways to make your living space modern ...
                    </a>
                  </h3>
                  <a href="/blogs" className="read-more">
                    Read more
                    <span className="icon flaticon-right-arrow-1"></span>
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- News Block --> */}
            <div className="news-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInRight"
                data-wow-delay="250ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <a href="/blogs">
                    <img
                      src={require("../resources/resource/news-2.jpg")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="lower-content">
                  <ul className="post-meta">
                    <li>
                      By <span>Philip .K</span>
                    </li>
                    <li>Design ideas</li>
                  </ul>
                  <h3>
                    <a href="/blogs">
                      Storage ideas for your kitchen by top designers ...
                    </a>
                  </h3>
                  <a href="/blogs" className="read-more">
                    Read more{" "}
                    <span className="icon flaticon-right-arrow-1"></span>
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- News Block --> */}
            <div className="news-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInRight"
                data-wow-delay="500ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <a href="/blogs">
                    <img
                      src={require("../resources/resource/news-3.jpg")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="lower-content">
                  <ul className="post-meta">
                    <li>
                      By <span>Kim</span>
                    </li>
                    <li>Awesome Ideas</li>
                  </ul>
                  <h3>
                    <a href="/blogs">Innovative Kids bedroom design ideas...</a>
                  </h3>
                  <a href="/blogs" className="read-more">
                    Read more
                    <span className="icon flaticon-right-arrow-1"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End News Section --> */}

      {/* <!-- Call To Action Section --> */}
      <section
        className="call-to-action-section"
        style={{ backgroundImage: `url(${callaction})` }}
      >
        <div className="auto-container">
          <h2>Think Quality. Think Mianzi Designs</h2>
          <div className="text">
            Where Imagination Meets Precision, and Every Space Tells a Unique
            Story.
            {/* <br /> designs. Heart your favorites to shortlist. */}
          </div>
          <Link to="/contact" className="theme-btn btn-style-two">
            <span className="txt">contact us</span>
          </Link>
        </div>
      </section>
      {/* <!-- End Call To Action Section --> */}
    </div>
  );
}

export default Home;
