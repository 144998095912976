import React from "react";
import { Link } from "react-router-dom";

function Testimonials() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{ backgroundImage: `url(images/background/5.jpg)` }}
      >
        <div class="auto-container">
          <h2>Testimonials</h2>
          <ul class="page-breadcrumb">
            <li>
              <Link to="/">home</Link>
            </li>
            <li>Testimonials</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- Testimonials Page Section --> */}
      <section class="testimonials-page-section">
        <div class="auto-container">
          {/* <!-- Page Title --> */}
          <div class="title-box">
            <h2>What Our Clients Say</h2>
            <div class="text">
              Thousands of People Take Our Interior Service
            </div>
          </div>

          <div class="row clearfix">
            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block col-lg-6 col-md-12 col-sm-12">
              <div class="inner-box">
                <div class="content">
                  <div class="image-outer">
                    <div class="image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                  </div>
                  <h3>Michale John</h3>
                  <div class="title">
                    I got luxuary inteior from Stella Orr'e
                  </div>
                  <div class="text">
                    Osed quia consequuntur magni dolores eos qui rati one
                    voluptatem sequi nesciunt. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur, adipisci sed
                    quia non numqua.
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block col-lg-6 col-md-12 col-sm-12">
              <div class="inner-box">
                <div class="content">
                  <div class="image-outer">
                    <div class="image">
                      <img src="images/resource/author-2.jpg" alt="" />
                    </div>
                  </div>
                  <h3>Michale John</h3>
                  <div class="title">
                    I got luxuary inteior from Stella Orr'e
                  </div>
                  <div class="text">
                    Osed quia consequuntur magni dolores eos qui rati one
                    voluptatem sequi nesciunt. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur, adipisci sed
                    quia non numqua.
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block col-lg-6 col-md-12 col-sm-12">
              <div class="inner-box">
                <div class="content">
                  <div class="image-outer">
                    <div class="image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                  </div>
                  <h3>Michale John</h3>
                  <div class="title">
                    I got luxuary inteior from Stella Orr'e
                  </div>
                  <div class="text">
                    Osed quia consequuntur magni dolores eos qui rati one
                    voluptatem sequi nesciunt. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur, adipisci sed
                    quia non numqua.
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block col-lg-6 col-md-12 col-sm-12">
              <div class="inner-box">
                <div class="content">
                  <div class="image-outer">
                    <div class="image">
                      <img src="images/resource/author-2.jpg" alt="" />
                    </div>
                  </div>
                  <h3>Michale John</h3>
                  <div class="title">
                    I got luxuary inteior from Stella Orr'e
                  </div>
                  <div class="text">
                    Osed quia consequuntur magni dolores eos qui rati one
                    voluptatem sequi nesciunt. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur, adipisci sed
                    quia non numqua.
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block col-lg-6 col-md-12 col-sm-12">
              <div class="inner-box">
                <div class="content">
                  <div class="image-outer">
                    <div class="image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                  </div>
                  <h3>Michale John</h3>
                  <div class="title">
                    I got luxuary inteior from Stella Orr'e
                  </div>
                  <div class="text">
                    Osed quia consequuntur magni dolores eos qui rati one
                    voluptatem sequi nesciunt. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur, adipisci sed
                    quia non numqua.
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block col-lg-6 col-md-12 col-sm-12">
              <div class="inner-box">
                <div class="content">
                  <div class="image-outer">
                    <div class="image">
                      <img src="images/resource/author-2.jpg" alt="" />
                    </div>
                  </div>
                  <h3>Michale John</h3>
                  <div class="title">
                    I got luxuary inteior from Stella Orr'e
                  </div>
                  <div class="text">
                    Osed quia consequuntur magni dolores eos qui rati one
                    voluptatem sequi nesciunt. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur, adipisci sed
                    quia non numqua.
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block col-lg-6 col-md-12 col-sm-12">
              <div class="inner-box">
                <div class="content">
                  <div class="image-outer">
                    <div class="image">
                      <img src="images/resource/author-1.jpg" alt="" />
                    </div>
                  </div>
                  <h3>Michale John</h3>
                  <div class="title">
                    I got luxuary inteior from Stella Orr'e
                  </div>
                  <div class="text">
                    Osed quia consequuntur magni dolores eos qui rati one
                    voluptatem sequi nesciunt. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur, adipisci sed
                    quia non numqua.
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block col-lg-6 col-md-12 col-sm-12">
              <div class="inner-box">
                <div class="content">
                  <div class="image-outer">
                    <div class="image">
                      <img src="images/resource/author-2.jpg" alt="" />
                    </div>
                  </div>
                  <h3>Michale John</h3>
                  <div class="title">
                    I got luxuary inteior from Stella Orr'e
                  </div>
                  <div class="text">
                    Osed quia consequuntur magni dolores eos qui rati one
                    voluptatem sequi nesciunt. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur, adipisci sed
                    quia non numqua.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Story Section --> */}
    </div>
  );
}

export default Testimonials;
