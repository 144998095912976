import React from "react";
import { Link } from "react-router-dom";

function CommercialInterior() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{ backgroundImage: `url(images/background/5.jpg)` }}
      >
        <div class="auto-container">
          <h2>Commercial Designs</h2>
          <ul class="page-breadcrumb">
            <li>
              <a href="index-2.html">home</a>
            </li>
            <li>Services</li>
            <li>Commercial Designs</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!--Sidebar Page Container--> */}
      <div class="sidebar-page-container">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Content Side / Services Detail --> */}
            <div class="content-side col-xl-9 col-lg-8 col-md-12 col-sm-12">
              <div class="services-detail">
                <div class="inner-box">
                  <div class="bold-text">
                    Our approach to commercial design is driven by a commitment
                    to reflecting your brand identity, enhancing employee
                    well-being, and optimizing operational efficiency.
                  </div>
                  <div class="text">
                    <p>
                      <strong>Branding Spaces for Success:</strong>
                      In the world of commercial design, we understand that your
                      space is an extension of your brand. Mianzi Designs
                      specializes in crafting environments that tell your brand
                      story, leaving a lasting impression on clients, customers,
                      and employees.
                    </p>
                    <p>
                      <strong>
                        Tailored Solutions for Diverse Businesses:
                      </strong>
                      From corporate offices to retail spaces, Mianzi Designs
                      provides tailored solutions that cater to diverse business
                      needs. Whether you're seeking a modern corporate aesthetic
                      or a vibrant retail environment, our designs are
                      customized to align with your industry and vision.
                    </p>

                    <p>
                      <strong>Employee-Centric Workspaces:</strong>
                      Our designs prioritize the well-being and productivity of
                      your team. Mianzi Designs creates employee-centric
                      workspaces that foster collaboration, creativity, and a
                      positive work culture. We understand the importance of a
                      well-designed office in attracting and retaining top
                      talent.
                    </p>
                    <p>
                      <strong>
                        Efficiency in Design, Efficiency in Operation:
                      </strong>
                      Efficiency is at the core of our commercial designs.
                      Mianzi Designs optimizes spatial layouts to enhance
                      workflow and operational efficiency. Every element is
                      strategically placed to create a seamless and productive
                      work environment.
                    </p>

                    <p>
                      <strong>
                        Innovative Use of Materials and Technology:
                      </strong>
                      Mianzi Designs stays at the forefront of design trends,
                      incorporating innovative materials and technology into
                      commercial spaces. From smart office solutions to
                      sustainable materials, our designs embrace the latest
                      advancements to keep your business ahead.
                    </p>

                    {/* <!-- Two Column --> */}
                    <div class="two-column">
                      <div class="row clearfix">
                        <div class="column col-lg-4 col-md-4 col-sm-12">
                          <div class="image">
                            <img src="images/resource/service-16.jpg" alt="" />
                          </div>
                          <div class="image">
                            <img
                              src={require("../resources/assets/media/projectdesignhf.jpg")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="content-column col-lg-8 col-md-8 col-sm-12">
                          <div class="inner-column">
                            <h3>Your Business, Our Design Expertise</h3>
                            <p>
                              Whether you're a startup looking for a fresh and
                              modern office space or an established brand
                              seeking a retail makeover, Mianzi Designs is here
                              to turn your commercial space into a strategic
                              asset. Collaborate with us to create a space that
                              speaks volumes about your business ethos.
                            </p>

                            <p>
                              <strong>
                                Aesthetic Harmony with Functionality:
                              </strong>
                              Commercial spaces need to balance aesthetics with
                              functionality. Mianzi Designs excels in achieving
                              aesthetic harmony, ensuring that your space not
                              only looks impressive but also serves its intended
                              purpose seamlessly.
                            </p>
                            <p>
                              Take a virtual tour through our commercial
                              portfolio to witness the transformative power of
                              our designs. Each project is a testament to our
                              commitment to creating spaces that elevate
                              businesses and leave a lasting impact.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="service-contact-box">
                      Our experienced Designers are ready for you.
                      <br /> <Link to="/contact">Contact our team</Link> to
                      learn more!
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--Sidebar Side--> */}
            <div class="sidebar-side col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <aside class="sidebar">
                {/* <!-- Sidebar Widget / List Widget--> */}
                <div class="sidebar-widget list-widget">
                  {/* <!-- Services List --> */}
                  <ul class="services-list">
                    <li>
                      <Link to="/residential">Residential Design</Link>
                    </li>
                    <li class="active">
                      <Link to="/commercial-interior">Commercial Design</Link>
                    </li>
                    <li>
                      <Link to="/office-design">Office Design</Link>
                    </li>
                    <li>
                      <Link to="/hospitality-design">Hospitality Design</Link>
                    </li>
                    <li>
                      <Link to="/modern-furniture">Modern Furniture</Link>
                    </li>
                    <li>
                      <Link to="/modern-kitchen">Modern Kitchen</Link>
                    </li>
                    <li>
                      <Link to="/wardrobe">Wardrobe</Link>
                    </li>
                    <li>
                      <Link to="/ceiling-design">Ceiling Designs</Link>
                    </li>
                  </ul>
                </div>

                {/* <!-- Sidebar Widget / Contact Widget--> */}
                <div class="sidebar-widget contact-widget">
                  <div
                    class="widget-content"
                    style={{ backgroundImage: `url(images/background/7.jpg)` }}
                  >
                    <h3>Get Free Quote</h3>
                    <Link to="/contact" class="theme-btn btn-style-one">
                      <span class="txt">contact us</span>
                    </Link>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommercialInterior;
