import React from "react";
import { Link } from "react-router-dom";
import merch1 from "../resources/assets/merchandise/mianzihoodie.jpg";
import merch2 from "../resources/assets/merchandise/mianzimerch.jpg";
import merch3 from "../resources/assets/merchandise/couplemerch.jpg";
import merch4 from "../resources/assets/merchandise/blacktmerch.jpg";

function Shop() {
  const merch = [
    {
      src: merch1,
      name: "Hoodie",
      price: "2000",
    },
    {
      src: merch2,
      name: "Pull neck",
      price: "2000",
    },
    {
      src: merch3,
      name: "White T-Shirt",
      price: "1500",
    },
    {
      src: merch4,
      name: "Black T-Shirt",
      price: "1500",
    },
  ];
  return (
    <div>
      {/* <!--Shop Banner Section--> */}
      <section
        className="shop-banner-section"
        style={{ backgroundImage: `url(images/background/8.jpg)` }}
      >
        <div className="auto-container">
          {/* <!-- Content Box --> */}
          <div className="content-box">
            <div className="box-inner">
              <h2>Merchandise</h2>
              <div className="text">
                Explore a curated selection of merchandise that reflects the
                essence of Mianzi's aesthetic, bringing design inspiration into
                your everyday life.
              </div>
              <Link to="/product/id" className="theme-btn btn-style-one">
                <span className="txt">Request</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Shop Banner Section--> */}

      {/* <!--Shop Features Section--> */}
      <section className="shop-features-section">
        <div className="auto-container">
          <div className="row clearfix">
            {/* <!--Feature Block--> */}
            <div className="shop-feature-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="icon-box">
                  <span className="icon flaticon-delivery-truck"></span>
                </div>
                <h3>
                  <a href="/">Free Aftersales</a>
                </h3>
                <div className="text">Great Aftersales Services</div>
              </div>
            </div>

            {/* <!--Feature Block--> */}
            <div className="shop-feature-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="icon-box">
                  <span className="icon flaticon-tag"></span>
                </div>
                <h3>
                  <a href="/">Sale 15% Off</a>
                </h3>
                <div className="text">Get great deals</div>
              </div>
            </div>

            {/* <!--Feature Block--> */}
            <div className="shop-feature-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="icon-box">
                  <span className="icon flaticon-store-new-badges"></span>
                </div>
                <h3>
                  <a href="/">New Ideas</a>
                </h3>
                <div className="text">Get inspired everyday</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Shop Features Section--> */}

      {/* <!--Shop Section--> */}
      <section className="shop-section">
        <div className="auto-container">
          {/* <!--Sec Title--> */}
          <div className="title-box">
            <h2>Featured</h2>
          </div>

          <div className="row clearfix">
            {/* <!--Shop Item--> */}
            {merch.map((m, index) => (
              <div className="shop-item col-lg-3 col-md-6 col-sm-12">
                <div key={index} className="inner-box">
                  <div className="image">
                    <a href="product-detail">
                      <img src={m.src} alt="" />
                    </a>
                    <div className="overlay-box">
                      <ul className="option-box">
                        <li>
                          <a href="/">
                            <span className="far fa-heart"></span>
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <span className="fa fa-shopping-bag"></span>
                          </a>
                        </li>
                        <li>
                          <a
                            href={m.src}
                            className="lightbox-image"
                            data-fancybox="products"
                          >
                            <span className="fa fa-search"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tag-banner">New</div>
                  </div>
                  <div className="lower-content">
                    <h3>
                      <a href="/">{m.name}</a>
                    </h3>
                    <div className="price">KES{m.price}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="shop-pagination">
            <ul className="clearfix">
              <li className="prev">
                <a href="/">
                  <i className="fa fa-angle-left"></i>
                </a>
              </li>
              <li>
                <a href="/">1</a>
              </li>
              <li className="active">
                <a href="/">2</a>
              </li>
              <li>
                <a href="/">3</a>
              </li>
              <li className="next">
                <a href="/">
                  <i className="fa fa-angle-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* <!--Products Section--> */}
      <section className="products-section">
        <div className="auto-container">
          <div className="row clearfix">
            {/* <!--Product Column--> */}
            <div className="product-column col-lg-6 col-md-12 col-sm-12">
              <div className="row clearfix">
                {/* <!--Shop Item Two--> */}
                <div className="shop-item-two col-lg-6 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <a href="#">
                        <img src={merch2} alt="" />
                      </a>
                    </div>
                    <div className="lower-content">
                      <h3>
                        <a href="product-detail.html">COLD CREWNECK</a>
                      </h3>
                      <div className="price">KES2,000</div>
                      <a href="#" className="theme-btn cart-btn">
                        Add to cart
                      </a>
                    </div>
                  </div>
                </div>

                {/* <!--Shop Item Two--> */}
                <div className="shop-item-two col-lg-6 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <a href="#">
                        <img src={merch1} alt="" />
                      </a>
                    </div>
                    <div className="lower-content">
                      <h3>
                        <a href="#">MULTI-WAY ULTRA</a>
                      </h3>
                      <div className="price">KES2,000</div>
                      <a href="#" className="theme-btn cart-btn">
                        Add to cart
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--Content Column--> */}
            <div className="content-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <h2>
                  Get Our <span>Merchandise</span>
                </h2>
                <div className="text">
                  Get your best look from our designer merchandise
                </div>
                <a href="/" className="theme-btn btn-style-one">
                  <span className="txt">View more</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Products Section--> */}
    </div>
  );
}

export default Shop;
