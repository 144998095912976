import React from "react";
import "./components.css";
import { Link, useLocation } from "react-router-dom";

function MobileMenu({ setMobile }) {
  const location = useLocation();
  const getLinkStyle = (path) => {
    return {
      color: location.pathname === path ? "orange" : "#fff",
    };
  };
  return (
    <div
      className="animate__fadeIn md:hidden block"
      style={{
        backgroundColor: "#272727",
        padding: 10,
        color: "white",
      }}
      onClick={() => {
        setMobile(false);
      }}
    >
      <div>
        <ul className="menulist">
          <Link to={"/"}>
            <li style={getLinkStyle("/")}>Home</li>
          </Link>
          <Link to={"/about"}>
            <li style={getLinkStyle("/about")}>About Us</li>
          </Link>
          <Link to={"/services"}>
            <li style={getLinkStyle("/services")}>Services</li>
          </Link>
          <Link to={"/projects"}>
            <li style={getLinkStyle("/projects")}>Projects</li>
          </Link>
          <Link to={"/blogs"}>
            <li style={getLinkStyle("/blogs")}>Blog</li>
          </Link>
          <Link to={"/shop"}>
            <li style={getLinkStyle("/shop")}>Shop</li>
          </Link>
          <Link to={"/contact"}>
            <li style={getLinkStyle("/contact")}>Contact us</li>
          </Link>
        </ul>
      </div>
      {/* <!--Social Links--> */}
      <div
        className="social-links"
        style={{
          justifyContent: "center",
        }}
      >
        <ul
          className="clearfix"
          style={{
            display: "flex",
            gap: 35,
            paddingTop: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <li>
            <a
              href="https://twitter.com/MianziD"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fab fa-twitter"></span>
            </a>
          </li>
          <li>
            <a
              href="https://facebook.com/people/Mianzi-Designs/100067205023809/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fab fa-facebook-f"></span>
            </a>
          </li>
          <li>
            <a
              href="https://ar.pinterest.com/mianzifurniture/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fab fa-pinterest-p"></span>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/mianzi-designs-707a20230/?originalSubdomain=ke"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fab fa-linkedin"></span>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/mianzi_global/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fab fa-instagram"></span>
            </a>
          </li>
          <li>
            <a href="/">
              <span className="fab fa-youtube"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MobileMenu;
