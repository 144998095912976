import React from "react";
import { Link } from "react-router-dom";

function Furniture() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{ backgroundImage: `url(images/background/5.jpg)` }}
      >
        <div class="auto-container">
          <h2>Modern Furniture</h2>
          <ul class="page-breadcrumb">
            <li>
              <Link to="/">home</Link>
            </li>
            <li>Services</li>
            <li>Modern Furniture</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!--Sidebar Page Container--> */}
      <div class="sidebar-page-container">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Content Side / Services Detail --> */}
            <div class="content-side col-xl-9 col-lg-8 col-md-12 col-sm-12">
              <div class="services-detail">
                <div class="inner-box">
                  <div class="bold-text">
                    Our modern furniture designs are crafted to enhance your
                    space, providing not just comfort but also a visual
                    aesthetic that stands out in the modern living landscape.
                  </div>
                  <div class="text">
                    <p>
                      <strong>Contemporary Elegance Redefined:</strong>
                      We bring a fresh perspective to modern furniture,
                      redefining contemporary elegance. Each piece is
                      meticulously designed to be a harmonious blend of style
                      and comfort, adding a touch of sophistication to your
                      living spaces.
                    </p>
                    <p>
                      <strong>Innovative Forms, Timeless Appeal:</strong>
                      Our modern furniture designs embrace innovative forms
                      while maintaining timeless appeal. Whether you seek
                      minimalist simplicity or bold, sculptural pieces, We
                      create furniture that serves as both functional art and an
                      integral part of your interior design.
                    </p>
                    <p>
                      <strong>Functional Artistry for Every Room:</strong>
                      From statement sofas in the living room to sleek dining
                      sets and ergonomic office furniture, Mianzi Designs offers
                      functional artistry for every room. Our pieces are
                      designed not just to occupy space but to elevate the
                      overall aesthetics of your home or workspace.
                    </p>
                    <p>
                      <strong>Materials of Tomorrow, Today:</strong> We stay at
                      the forefront of material innovation. Our modern furniture
                      incorporates cutting-edge materials that not only enhance
                      the visual appeal but also ensure durability and
                      sustainability, contributing to a more modern and
                      conscientious lifestyle.
                    </p>
                    {/* <!-- Two Column --> */}
                    <div class="two-column">
                      <div class="row clearfix">
                        <div class="column col-lg-4 col-md-4 col-sm-12">
                          <div class="image">
                            <img src="images/resource/service-26.jpg" alt="" />
                          </div>
                          <div class="image">
                            <img
                              src={require("../resources/assets/media/wardrobe.jpg")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="content-column col-lg-8 col-md-8 col-sm-12">
                          <div class="inner-column">
                            <h3>Customized Comfort and Style</h3>
                            <p>
                              Experience the luxury of customization with Mianzi
                              Designs. Our modern furniture designs are
                              adaptable to your unique preferences, allowing you
                              to tailor each piece to suit your taste, ensuring
                              that your furniture is an extension of your
                              personal style.
                            </p>
                            <p>
                              <strong>
                                Explore Our Modern Furniture Collection:
                              </strong>
                              Browse through our collection of modern furniture
                              to witness the diversity of our designs. Each
                              piece showcases our commitment to creating
                              furniture that is not just functional but also a
                              visual delight.
                            </p>
                            <p>
                              <strong>Your Space, Modernized:</strong>
                              We invite you to modernize your space with our
                              curated collection of contemporary furniture.
                              Whether you're furnishing a new home or revamping
                              your existing interiors, our team is ready to
                              transform your vision into modern living reality.
                            </p>
                            <p>
                              Each piece is a testament to our dedication to
                              redefining modern living with style, comfort, and
                              innovation.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="service-contact-box">
                      Our experienced Designers help for your home interior.{" "}
                      <br /> <Link to="/contact">Contact us</Link> to learn
                      more!
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--Sidebar Side--> */}
            <div class="sidebar-side col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <aside class="sidebar">
                {/* <!-- Sidebar Widget / List Widget--> */}
                <div class="sidebar-widget list-widget">
                  {/* <!-- Services List --> */}
                  <ul class="services-list">
                    <li>
                      <Link to="/residential">Residential Interior</Link>
                    </li>
                    <li>
                      <Link to="/commercial-interior">Commercial Interior</Link>
                    </li>
                    <li>
                      <Link to="/office-design">Office Designs</Link>
                    </li>
                    <li>
                      <Link to="/hospitality-design">Hospitality Design</Link>
                    </li>
                    <li class="active">
                      <Link to="/modern-furniture">Modern Furniture</Link>
                    </li>
                    <li>
                      <Link to="/modern-kitchen">Modern Kitchen</Link>
                    </li>
                    <li>
                      <Link to="/wardrobe">Wardrobe</Link>
                    </li>
                    <li>
                      <Link to="/ceiling-design">Ceiling Designs</Link>
                    </li>
                  </ul>
                </div>

                {/* <!-- Sidebar Widget / Contact Widget--> */}
                <div class="sidebar-widget contact-widget">
                  <div
                    class="widget-content"
                    style={{ backgroundImage: `url(images/background/7.jpg)` }}
                  >
                    <h3>Get Free Quote</h3>
                    <Link to="/contact" class="theme-btn btn-style-one">
                      <span class="txt">contact us</span>
                    </Link>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Furniture;
