import React from "react";
import { Link } from "react-router-dom";

function Wardrobe() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{ background: `url(images/background/5.jpg)` }}
      >
        <div class="auto-container">
          <h2>Wardrobe</h2>
          <ul class="page-breadcrumb">
            <li>
              <Link href="/">home</Link>
            </li>
            <li>Services</li>
            <li>Wardrobe</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!--Sidebar Page Container--> */}
      <div class="sidebar-page-container">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Content Side / Services Detail --> */}
            <div class="content-side col-xl-9 col-lg-8 col-md-12 col-sm-12">
              <div class="services-detail">
                <div class="inner-box">
                  <div class="bold-text">
                    Our wardrobe designs are crafted to transform storage into a
                    luxurious experience, offering a perfect blend of
                    organization and elegance.
                  </div>
                  <div class="text">
                    <p>
                      <strong>Closet Couture, Personalized:</strong>
                      We bring a touch of couture to your closet. Our wardrobe
                      designs are a fusion of practical storage solutions and
                      personalized style, ensuring that every garment has its
                      own curated space.
                    </p>
                    <p>
                      <strong>Elegance in Organization:</strong>
                      Discover the elegance of organized living with Mianzi's
                      wardrobe designs. We believe that a well-designed wardrobe
                      not only maximizes space but also elevates the overall
                      aesthetic of your bedroom or dressing area.
                    </p>
                    <p>
                      <strong>Customizable Storage Solutions:</strong>
                      Tailor your wardrobe to your lifestyle with Mianzi
                      Designs. Our customizable storage solutions cater to your
                      unique needs, from spacious walk-in closets to compact yet
                      efficient built-in wardrobes.
                    </p>
                    <p>
                      <strong>Luxurious Materials, Timeless Appeal:</strong>
                      We select luxurious materials to ensure both durability
                      and timeless appeal. From premium wood finishes to bespoke
                      hardware, our wardrobe designs exemplify a commitment to
                      quality and sophistication.
                    </p>
                    <p>
                      <strong>Efficiency Meets Elegance:</strong>
                      Our wardrobe designs strike the perfect balance between
                      efficiency and elegance. With intuitive layouts, smart
                      storage solutions, and a keen eye for detail, Mianzi
                      creates closets that are both highly functional and
                      visually stunning.
                    </p>
                    {/* <!-- Two Column --> */}
                    <div class="two-column">
                      <div class="row clearfix">
                        <div class="column col-lg-4 col-md-4 col-sm-12">
                          <div class="image">
                            <img src="images/resource/service-20.jpg" alt="" />
                          </div>
                          <div class="image">
                            <img
                              src={require("../resources/assets/media/wardrobe.jpg")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="content-column col-lg-8 col-md-8 col-sm-12">
                          <div class="inner-column">
                            <h3>Dressing Spaces as Personal Sanctuaries.</h3>
                            <p>
                              Mianzi Designs envisions dressing spaces as
                              personal sanctuaries. Each wardrobe design is
                              crafted to enhance your daily ritual, ensuring
                              that getting ready is a pleasurable and stylish
                              experience.
                            </p>
                            <p>
                              <strong>Explore Our Wardrobe Portfolio:</strong>
                              Browse through our portfolio of wardrobe designs
                              to witness the diversity of our creations. Each
                              project showcases our dedication to creating
                              wardrobes that are not just storage solutions but
                              also reflections of your personal style.
                            </p>
                            <p>
                              <strong>
                                Collaborate for a Wardrobe Makeover:
                              </strong>
                              We invite you to collaborate in creating your
                              dream wardrobe. Whether you desire a walk-in
                              closet with boutique charm or a sleek modern
                              wardrobe, our team is ready to turn your wardrobe
                              dreams into a reality.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="service-contact-box">
                      Our experienced Designers are ready of you.
                      <br /> <a href="/contact">Contact us</a> to learn more!
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--Sidebar Side--> */}
            <div class="sidebar-side col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <aside class="sidebar">
                {/* <!-- Sidebar Widget / List Widget--> */}
                <div class="sidebar-widget list-widget">
                  {/* <!-- Services List --> */}
                  <ul class="services-list">
                    <li>
                      <a href="/residential">Residential Design</a>
                    </li>
                    <li>
                      <a href="/commercial-interior">Commercial Design</a>
                    </li>
                    <li>
                      <a href="/office-design">Office Designs</a>
                    </li>
                    <li>
                      <a href="/hospitality-design">Hospitality Design</a>
                    </li>
                    <li>
                      <a href="/modern-furniture">Modern Furniture</a>
                    </li>
                    <li>
                      <a href="/modern-kitchen">Modern Kitchen</a>
                    </li>
                    <li class="active">
                      <a href="/wardrobe">Wardrobe</a>
                    </li>
                    <li>
                      <a href="/ceiling-design">Ceiling Designs</a>
                    </li>
                  </ul>
                </div>

                {/* <!-- Sidebar Widget / Contact Widget--> */}
                <div class="sidebar-widget contact-widget">
                  <div
                    class="widget-content"
                    style={{ backgroundImage: `url(images/background/7.jpg)` }}
                  >
                    <h3>Get Free Quote</h3>
                    <a href="/contact" class="theme-btn btn-style-one">
                      <span class="txt">contact us</span>
                    </a>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wardrobe;
