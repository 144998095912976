import React from "react";
import { Link } from "react-router-dom";

function Hospitality() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{ backgroundImage: `url(images/background/5.jpg)` }}
      >
        <div class="auto-container">
          <h2>Hospitality Design</h2>
          <ul class="page-breadcrumb">
            <li>
              <Link to="/">home</Link>
            </li>
            <li>Services</li>
            <li>Hospitality Designs</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!--Sidebar Page Container--> */}
      <div class="sidebar-page-container">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Content Side / Services Detail --> */}
            <div class="content-side col-xl-9 col-lg-8 col-md-12 col-sm-12">
              <div class="services-detail">
                <div class="inner-box">
                  <div class="bold-text">
                    Transform spaces into immersive experiences that captivate
                    guests and elevate your brand with Mianzi Designs. Our
                    hospitality designs blend luxury with functionality,
                    creating environments that leave a lasting impression and
                    enhance the overall guest experience.
                  </div>
                  <div class="text">
                    <p>
                      <strong>Crafting Memorable Guest Journeys:</strong>
                      Mianzi Designs specializes in crafting hospitality spaces
                      that go beyond aesthetics to create memorable guest
                      journeys. From hotels to restaurants, each design element
                      is carefully curated to evoke a unique ambiance that
                      resonates with your brand story.
                    </p>
                    <p>
                      <strong>Balancing Luxury with Practicality:</strong>
                      Luxury and practicality are seamlessly integrated into our
                      hospitality designs. Mianzi Designs ensures that every
                      space not only exudes opulence but is also efficiently
                      designed to meet the operational needs of your business.
                    </p>
                    <p>
                      <strong>Immersive Restaurant Experiences:</strong>
                      For restaurants, we create immersive dining experiences
                      that go beyond the culinary. Our designs enhance the
                      ambiance, creating a perfect backdrop for culinary
                      excellence and making every meal a memorable occasion.
                    </p>
                    <p>
                      <strong>Hotel Designs that Welcome and Wow:</strong>
                      In the realm of hotels, Mianzi Designs crafts designs that
                      welcome guests with warmth and wow them with
                      sophistication. From lobbies to suites, our designs
                      contribute to the overall guest experience, ensuring they
                      feel pampered and inspired.
                    </p>
                    {/* <!-- Two Column --> */}
                    <div class="two-column">
                      <div class="row clearfix">
                        <div class="column col-lg-4 col-md-4 col-sm-12">
                          <div class="image">
                            <img src="images/resource/service-28.jpg" alt="" />
                          </div>
                          <div class="image">
                            <img src="images/resource/service-29.jpg" alt="" />
                          </div>
                          <div class="image">
                            <img
                              src={require("../resources/assets/media/roomdesign1hf.jpg")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="content-column col-lg-8 col-md-8 col-sm-12">
                          <div class="inner-column">
                            <h3>Functional Design for Effortless Operations</h3>
                            <p>
                              We understand the importance of functionality in
                              the hospitality industry. Our designs are not only
                              visually stunning but also optimized for efficient
                              operations, ensuring a seamless and enjoyable
                              experience for both guests and staff.
                            </p>
                            <p>
                              <strong>
                                Tailored Solutions for Diverse Venues:
                              </strong>
                              Whether you're envisioning a boutique hotel, a
                              chic restaurant, or a vibrant café, Mianzi Designs
                              provides tailored solutions. Our designs cater to
                              the unique personality and requirements of each
                              hospitality venue, ensuring a cohesive and
                              inviting atmosphere.
                            </p>
                            <p>
                              <strong>
                                Explore Our Hospitality Portfolio:
                              </strong>
                              Take a virtual tour through our portfolio of
                              hospitality designs to witness the transformative
                              power of our creations. Each project reflects our
                              commitment to creating spaces that enhance the
                              overall guest experience.
                            </p>
                            <p>
                              <strong>
                                Collaborate for Unforgettable Spaces:
                              </strong>
                              We invite you to collaborate in creating
                              unforgettable hospitality spaces. Whether you're
                              launching a new restaurant or revitalizing a
                              hotel, our team is ready to turn your vision into
                              a captivating reality.
                            </p>
                            <p>
                              Welcome to Our Hospitality Designs, where every
                              space tells a story and every guest experience is
                              crafted with precision and flair.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="service-contact-box">
                      Our experienced Designers are waiting for you.
                      <br /> <Link to="/contact">Contact us</Link> to learn
                      more!
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--Sidebar Side--> */}
            <div class="sidebar-side col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <aside class="sidebar">
                {/* <!-- Sidebar Widget / List Widget--> */}
                <div class="sidebar-widget list-widget">
                  {/* <!-- Services List --> */}
                  <ul class="services-list">
                    <li>
                      <Link to="/residential">Residential Interior</Link>
                    </li>
                    <li>
                      <Link to="/commercial-interior">Commercial Interior</Link>
                    </li>
                    <li>
                      <Link to="/office-interior">Office Designs</Link>
                    </li>
                    <li class="active">
                      <Link to="/hospitality-design">Hospitality Design</Link>
                    </li>
                    <li>
                      <Link to="/modern-furniture">Modern Furniture</Link>
                    </li>
                    <li>
                      <Link to="/modern-kitchen">Modern Kitchen</Link>
                    </li>
                    <li>
                      <Link to="/wardrobe">Wardrobe</Link>
                    </li>
                    <li>
                      <Link to="/ceiling-design">Ceiling Designs</Link>
                    </li>
                  </ul>
                </div>

                {/* <!-- Sidebar Widget / Contact Widget--> */}
                <div class="sidebar-widget contact-widget">
                  <div
                    class="widget-content"
                    style={{ backgroundImage: `url(images/background/7.jpg)` }}
                  >
                    <h3>Get Free Quote</h3>
                    <Link to="/contact" class="theme-btn btn-style-one">
                      <span class="txt">contact us</span>
                    </Link>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hospitality;
