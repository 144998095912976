import React, { useState } from "react";
import Footer from "./Footer";
import { Link, Outlet, useLocation } from "react-router-dom";
import { message } from "antd";
import MobileMenu from "./MobileMenu";
import SmoothScroll from "./SmoothScroll";

function Appbar() {
  const [mobile, setMobile] = useState(false);
  const location = useLocation();
  const backtop = () => {
    window.scrollTo(0, 0);
  };
  const getLinkStyle = (path) => {
    return {
      color: location.pathname === path ? "orange" : "#fff",
    };
  };

  return (
    <>
      <SmoothScroll>
        <div className="page-wrapper">
          {/* <div className="preloader"></div> */}
          <header className="main-header header-style-one">
            {/* <!--Header Top--> */}
            <div className="header-top">
              <div className="auto-container clearfix">
                <div className="top-left clearfix">
                  <div className="text">
                    <span className="icon flaticon-call-answer"></span> Need
                    help?
                    {/* Call Us Now : */}
                    {/* <a href="tel:+254725177878" className="number">
                      +254725177878
                    </a> */}
                  </div>
                </div>
                <div className="top-right clearfix">
                  {/* <!-- Info List --> */}
                  <ul className="info-list">
                    <li>
                      <Link to="about">Our Story</Link>
                    </li>
                    <li>
                      <Link to="/blogs">Inspiration</Link>
                    </li>
                    <li className="quote">
                      <p>Get Free Quote</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- End Header Top --> */}

            {/* <!-- Header Upper --> */}
            <div className="header-upper">
              <div className="inner-container">
                <div className="auto-container clearfix">
                  {/* <!--Info--> */}
                  <div className="logo-outer">
                    <div
                      className="logo"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        marginTop: "16px",
                        marginBotton: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      <Link to="/">
                        <p
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            fontWeight: "semibold",
                          }}
                        >
                          Mianzi
                          <span style={{ color: "#dfb162" }}>Designs</span>
                        </p>
                      </Link>
                    </div>
                  </div>

                  {/* <!--Nav Box--> */}
                  <div className="nav-outer clearfix">
                    {/* <!--Mobile Navigation Toggler For Mobile--> */}
                    <div
                      onClick={() => {
                        setMobile(!mobile);
                      }}
                      className="mobile-nav-toggler"
                    >
                      {mobile ? (
                        <span className="icon flaticon-cancel"></span>
                      ) : (
                        <span className="icon flaticon-menu-1"></span>
                      )}
                    </div>

                    <nav className="main-menu navbar-expand-md navbar-light">
                      <div className="navbar-header">
                        {/* <!-- Togg le Button -->       */}
                        <button
                          className="navbar-toggler"
                          type="button"
                          data-toggle="collapse"
                          data-target="#navbarSupportedContent"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                          onClick={() => {
                            message.info("mobile menu clicked");
                          }}
                        >
                          <span className="icon flaticon-menu-1"></span>
                        </button>
                      </div>

                      <div
                        className="collapse navbar-collapse clearfix"
                        id="navbarSupportedContent"
                      >
                        <ul className="navigation clearfix">
                          <li className="current dropdown">
                            <Link to="/" style={getLinkStyle("/")}>
                              Home
                            </Link>
                          </li>
                          <li className="dropdown">
                            <Link to="/about" style={getLinkStyle("/about")}>
                              About us
                            </Link>
                          </li>
                          <li className="dropdown">
                            <Link
                              to="services"
                              style={getLinkStyle("/services")}
                            >
                              Services
                            </Link>
                            <ul>
                              <li>
                                <Link to="/residential">
                                  Residential Interior
                                </Link>
                              </li>
                              <li>
                                <Link to="/commercial-interior">
                                  Commercial Interior
                                </Link>
                              </li>
                              {/* <li>
                                <Link to="/office-design">Office Design</Link>
                              </li>
                              <li>
                                <Link to="/hospitality-design">
                                  Hospitality Design
                                </Link>
                              </li>
                              <li>
                                <Link to="/modern-furniture">
                                  Modern Furniture
                                </Link>
                              </li> */}
                              <li>
                                <Link to="/modern-kitchen">Modern Kitchen</Link>
                              </li>

                              {/* <li>
                                <Link to="/ceiling-design">Ceiling Design</Link>
                              </li> */}
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              to="/projects"
                              style={getLinkStyle("/projects")}
                            >
                              Projects
                            </Link>
                          </li>
                          <li className="dropdown">
                            <Link to="/blogs" style={getLinkStyle("/blogs")}>
                              Blog
                            </Link>
                          </li>
                          <li className="dropdown">
                            <Link to="/shop" style={getLinkStyle("/shop")}>
                              Shop
                            </Link>
                            {/* <ul>
                            <li>
                              <a href="shop.html">Main shop page</a>
                            </li>
                            <li>
                              <a href="product-detail.html">
                                Product Detail Page
                              </a>
                            </li>
                            <li>
                              <a href="cart-page.html">Cart Page</a>
                            </li>
                            <li>
                              <a href="checkout.html">Checkout</a>
                            </li>
                          </ul> */}
                          </li>
                          <li>
                            <Link
                              to="/contact"
                              style={getLinkStyle("/contact")}
                            >
                              Contact
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                    {/* <!-- Main Menu End--> */}

                    {/* <!-- Outer Box --> */}
                    {/* <div className="outer-box clearfix">
                      <div className="search-box-btn">
                        <span className="icon flaticon-magnifying-glass-1"></span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* end header top */}
            {/* <!-- Mobile Menu  --> */}

            {mobile && <MobileMenu setMobile={setMobile} />}
            <div className="mobile-menu">
              <div className="menu-backdrop"></div>
              <div className="close-btn">
                <span className="icon flaticon-cancel"></span>
              </div>

              <nav className="menu-box">
                <div className="nav-logo">
                  <Link to="/">
                    {/* <img src="images/logo.png" alt="" title="" /> */}
                  </Link>
                </div>
                <ul className="navigation clearfix">
                  {/* <!--Keep This Empty / Menu will come through Javascript--> */}
                </ul>
                {/* <!--Social Links--> */}
                <div className="social-links">
                  <ul className="clearfix">
                    <li>
                      <a href="/">
                        <span className="fab fa-twitter"></span>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <span className="fab fa-facebook-square"></span>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <span className="fab fa-pinterest-p"></span>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <span className="fab fa-instagram"></span>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <span className="fab fa-youtube"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            {/* <!-- End Mobile Menu --> */}
          </header>
          {/* <!--Scroll to top--> */}
          <div
            onClick={() => backtop()}
            className="scroll-to-top scroll-to-target"
            data-target="html"
          >
            <span className="fa fa-angle-up"></span>
          </div>

          {/* <!--Search Popup--> */}
          <div id="search-popup" className="search-popup">
            <div className="close-search theme-btn">
              <span className="flaticon-cancel"></span>
            </div>
            <div className="popup-inner">
              <div className="overlay-layer"></div>
              <div className="search-form">
                <form method="post" action="templateshub.net">
                  <div className="form-group">
                    <fieldset>
                      <input
                        type="search"
                        className="form-control"
                        name="search-input"
                        value=""
                        placeholder="Search Here"
                        required
                      />
                      <input
                        type="submit"
                        value="Search Now!"
                        className="theme-btn"
                      />
                    </fieldset>
                  </div>
                </form>

                <br />
                <h3>Recent Search Keywords</h3>
                <ul className="recent-searches">
                  <li>
                    <Link to="/">Home Interiors</Link>
                  </li>
                  <li>
                    <Link to="#">Offices Interiors</Link>
                  </li>
                  <li>
                    <Link to="#">Showroom Interiors</Link>
                  </li>
                  <li>
                    <Link to="#">Building Interiors</Link>
                  </li>
                  <li>
                    <Link to="#">Shops Interiors</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Outlet />
          <Footer />
        </div>
      </SmoothScroll>
    </>
  );
}

export default Appbar;
