import React from "react";
import { Link } from "react-router-dom";

function Kitchen() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{ backgroundImage: `url(images/background/5.jpg)` }}
      >
        <div class="auto-container">
          <h2>Modern Kitchen</h2>
          <ul class="page-breadcrumb">
            <li>
              <Link to="/">home</Link>
            </li>
            <li>Services</li>
            <li>Modern Kitchen</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!--Sidebar Page Container--> */}
      <div class="sidebar-page-container">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Content Side / Services Detail --> */}
            <div class="content-side col-xl-9 col-lg-8 col-md-12 col-sm-12">
              <div class="services-detail">
                <div class="inner-box">
                  <div class="bold-text">
                    Our modern kitchen designs redefine the heart of the home,
                    offering a perfect blend of style, innovation, and
                    practicality.
                  </div>
                  <div class="text">
                    <p>
                      <strong>Culinary Spaces Transformed:</strong>
                      Mianzi Designs specializes in transforming kitchens into
                      culinary havens. Our modern kitchen designs are a
                      celebration of form and function, creating spaces where
                      cooking is a pleasure and every meal is an experience.
                    </p>
                    <p>
                      <strong>Sleek Simplicity, Endless Possibilities:</strong>
                      Experience the allure of sleek simplicity with Mianzi's
                      modern kitchen designs. Each element is thoughtfully
                      curated for both aesthetic appeal and practical
                      efficiency, offering endless possibilities for culinary
                      exploration.
                    </p>
                    <p>
                      <strong>Innovative Layouts for Modern Living:</strong>
                      Our designs optimize spatial layouts to align with the
                      dynamics of modern living. From smart storage solutions to
                      intuitive workflow designs, Mianzi creates kitchens that
                      cater to the needs of contemporary lifestyles.
                    </p>
                    <p>
                      <strong>Cutting-Edge Materials and Finishes:</strong>
                      We stay ahead of design trends with the use of
                      cutting-edge materials and finishes. Our modern kitchens
                      showcase a fusion of textures, colors, and materials,
                      contributing to a visually stunning and cohesive kitchen
                      environment.
                    </p>
                    <p>
                      <strong>Personalized Solutions for Every Chef:</strong>
                      Whether you're a culinary enthusiast or a professional
                      chef, Mianzi Designs offers personalized kitchen
                      solutions. From ergonomic layouts to custom storage, we
                      tailor each design to match your cooking style and
                      preferences.
                    </p>
                    {/* <!-- Two Column --> */}
                    <div class="two-column">
                      <div class="row clearfix">
                        <div class="column col-lg-4 col-md-4 col-sm-12">
                          <div class="image">
                            <img src="images/resource/service-24.jpg" alt="" />
                          </div>
                          <div class="image">
                            <img
                              src={require("../resources/assets/media/kitchenhf.jpg")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="content-column col-lg-8 col-md-8 col-sm-12">
                          <div class="inner-column">
                            <h3>Seamless Integration of Technology</h3>
                            <p>
                              Experience the future of cooking with Mianzi
                              Designs. Our modern kitchens seamlessly integrate
                              the latest in kitchen technology, from smart
                              appliances to innovative lighting solutions,
                              enhancing both the functionality and aesthetics of
                              your culinary space.
                            </p>
                            <p>
                              <strong>
                                Explore Our Modern Kitchen Portfolio:
                              </strong>
                              Take a virtual tour through our portfolio of
                              modern kitchens to witness the transformative
                              power of our designs. Each project reflects our
                              commitment to creating kitchens that are both
                              visually stunning and highly efficient.
                            </p>
                            <p>
                              <strong>
                                Collaborate for Your Culinary Haven:
                              </strong>
                              We invite you to collaborate in creating your
                              dream kitchen. Whether you envision a sleek
                              minimalist design or a warm and inviting culinary
                              space, our team is ready to turn your kitchen
                              dreams into a reality.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="service-contact-box">
                      Our experienced Designers are ready for you.
                      <br /> <Link to="/contact">Contact us</Link> to learn
                      more!
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--Sidebar Side--> */}
            <div class="sidebar-side col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <aside class="sidebar">
                {/* <!-- Sidebar Widget / List Widget--> */}
                <div class="sidebar-widget list-widget">
                  {/* <!-- Services List --> */}
                  <ul class="services-list">
                    <li>
                      <Link to="/residential">Residential Design</Link>
                    </li>
                    <li>
                      <Link to="/commercial-interior">Commercial Design</Link>
                    </li>
                    <li>
                      <Link to="/office-design">Office Designs</Link>
                    </li>
                    <li>
                      <Link to="/hospitality-design">Hospitality Design</Link>
                    </li>
                    <li>
                      <Link to="/modern-furniture">Modern Furniture</Link>
                    </li>
                    <li class="active">
                      <Link to="/modern-kitchen">Modern Kitchen</Link>
                    </li>
                    <li>
                      <Link to="/wardrobe">Wardrobe</Link>
                    </li>
                    <li>
                      <Link to="/ceiling-design">Ceiling Designs</Link>
                    </li>
                  </ul>
                </div>

                {/* <!-- Sidebar Widget / Contact Widget--> */}
                <div class="sidebar-widget contact-widget">
                  <div
                    class="widget-content"
                    style={{ backgroundImage: `url(images/background/7.jpg)` }}
                  >
                    <h3>Get Free Quote</h3>
                    <Link to="/contact" class="theme-btn btn-style-one">
                      <span class="txt">contact us</span>
                    </Link>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Kitchen;
