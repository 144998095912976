import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home";
import Appbar from "./components/Appbar";
import ErrorPage from "./utils/ErrorPage";
import About from "./pages/About";
import Services from "./pages/Services";
import Projects from "./pages/Projects";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Shop from "./pages/Shop";
import Residential from "./pages/Residential";
import CommercialInterior from "./pages/CommercialInterior";
import Testimonials from "./pages/Testimonials";
import OfficeInterior from "./pages/OfficeInterior";
import Hospitality from "./pages/Hospitality";
import Furniture from "./pages/Furniture";
import Ceiling from "./pages/Ceiling";
import Kitchen from "./pages/Kitchen";
import Wardrobe from "./pages/Wardrobe";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorPage />} element={<Appbar />}>
      <Route errorElement={<ErrorPage />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/residential" element={<Residential />} />
        <Route path="/commercial-interior" element={<CommercialInterior />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/office-design" element={<OfficeInterior />} />
        <Route path="/hospitality-design" element={<Hospitality />} />
        <Route path="/modern-furniture" element={<Furniture />} />
        <Route path="/ceiling-design" element={<Ceiling />} />
        <Route path="/modern-kitchen" element={<Kitchen />} />
        <Route path="/wardrobe" element={<Wardrobe />} />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
