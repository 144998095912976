import React from "react";
import { Link } from "react-router-dom";

function Residential() {
  return (
    <>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{ backgroundImage: `url(images/background/5.jpg)` }}
      >
        <div class="auto-container">
          <h2>Residential Designs</h2>
          <ul class="page-breadcrumb">
            <li>
              <Link to="/">home</Link>
            </li>
            <li>Services</li>
            <li>Residential Designs</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}
      {/* <!--Sidebar Page Container--> */}
      <div class="sidebar-page-container">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Content Side / Services Detail --> */}
            <div class="content-side col-xl-9 col-lg-8 col-md-12 col-sm-12">
              <div class="services-detail">
                <div class="inner-box">
                  <div class="bold-text">
                    Welcome to Mianzi Designs' Residential Designs, where the
                    art of architectural storytelling meets the essence of your
                    unique lifestyle. Our approach to residential design is a
                    seamless blend of creativity and functionality, creating
                    homes that transcend the ordinary.
                  </div>
                  <div class="text">
                    <p>
                      <strong>Crafting Personalized Living Spaces:</strong>
                      In the realm of residential designs, we believe that each
                      home should be a reflection of its inhabitants. Our
                      process begins with a personalized consultation, delving
                      into your tastes, preferences, and the rhythm of your
                      daily life.
                    </p>
                    <p>
                      <strong>Tailored to Your Lifestyle:</strong>
                      Mianzi Designs specializes in tailoring spaces to match
                      your lifestyle. From cozy family homes to modern bachelor
                      pads, each design is carefully curated to suit your needs.
                      We pay meticulous attention to spatial layouts, ensuring
                      that every corner serves a purpose and contributes to the
                      overall harmony of the home.
                    </p>
                    <p>
                      <strong>Aesthetic Elegance with a Purpose:</strong>
                      Our residential designs are not just about aesthetics;
                      they are about purposeful elegance. We incorporate
                      timeless design principles, innovative materials, and
                      architectural elements that stand the test of time. The
                      result is a home that is both sophisticated and practical.
                    </p>
                    <p>
                      <strong>
                        Seamless Integration of Indoor and Outdoor Spaces:
                      </strong>
                      Mianzi Designs understands the importance of the
                      connection between indoor and outdoor living. Our designs
                      often incorporate seamless transitions, blurring the lines
                      between the interior and exterior, allowing you to
                      experience nature and light within the comfort of your
                      home.
                    </p>
                    <p>
                      <strong>Mastering the Art of Details:</strong>
                      It's the details that elevate a residence from ordinary to
                      extraordinary. Mianzi Designs excels in the art of
                      details, from custom-built furniture to unique
                      architectural elements. Every element is carefully
                      considered to contribute to the overall ambiance and
                      character of your home.
                    </p>
                    {/* <!-- Two Column --> */}
                    <div class="two-column">
                      <div class="row clearfix">
                        <div class="column col-lg-4 col-md-4 col-sm-12">
                          <div class="image">
                            <img
                              src={require("../resources/assets/media/chandeleirhf.jpg")}
                              alt=""
                            />
                          </div>
                          <div class="image">
                            <img src="images/resource/service-17.jpg" alt="" />
                          </div>
                        </div>
                        <div class="content-column col-lg-8 col-md-8 col-sm-12">
                          <div class="inner-column">
                            <h3>Your Dream Home, Our Expertise:</h3>
                            <p>
                              Whether you're dreaming of a modern minimalist
                              oasis or a traditional haven, Mianzi Designs
                              brings your vision to life. Our team of skilled
                              architects and designers collaborates closely with
                              you, ensuring that your dream home is realized
                              with precision and passion.
                            </p>
                            <p>
                              <strong>
                                Explore Our Residential Portfolio:
                              </strong>
                              Browse through our residential portfolio to
                              witness the diversity of our designs. Each project
                              tells a unique story, capturing the essence of the
                              individuals who call it home.
                            </p>
                            <p>
                              Welcome to a world where residential designs are
                              not just about spaces; they're about the art of
                              living well. Discover the Mianzi difference in
                              crafting homes that are truly yours.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4>Best Designers Around</h4>
                    <ul class="list-style-four">
                      <li>- Complete solutions for all scenarios</li>
                      <li>
                        - Design consultancy within your budget and matching
                        your needs
                      </li>
                      <li>- Redesigning or renovation of your space</li>
                      <li>
                        - Furnishing, art and accessories to create inviting,
                        soothing & comfortable ambience .
                      </li>
                      <li>- Redesigning or renovation of your space</li>
                    </ul>
                    <div class="service-contact-box">
                      Our experienced Designers help for your home interior.
                      <br /> <Link to="/contact">Contact our team</Link> to
                      learn more!
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--Sidebar Side--> */}
            <div class="sidebar-side col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <aside class="sidebar">
                {/* <!-- Sidebar Widget / List Widget--> */}
                <div class="sidebar-widget list-widget">
                  {/* <!-- Services List --> */}
                  <ul class="services-list">
                    <li class="active">
                      <Link to="/residental">Residential Design</Link>
                    </li>
                    <li>
                      <Link to="/commercial-interior">Commercial Design</Link>
                    </li>
                    <li>
                      <Link to="/office-design">Office Designs</Link>
                    </li>
                    <li>
                      <Link to="/hospitality-design">Hospitality Design</Link>
                    </li>
                    <li>
                      <Link to="/modern-furniture">Modern Furniture</Link>
                    </li>
                    <li>
                      <Link to="/modern-kitchen">Modern Kitchen</Link>
                    </li>
                    <li>
                      <Link to="/wardrobe">Wardrobe</Link>
                    </li>
                    <li>
                      <Link to="false-celling-design.html">
                        False Celling Designs
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* <!-- Sidebar Widget / Contact Widget--> */}
                <div class="sidebar-widget contact-widget">
                  <div
                    class="widget-content"
                    style={{ backgroundImage: `url(images/background/7.jpg)` }}
                  >
                    <h3>Get Free Quote</h3>
                    <Link to="/contact" class="theme-btn btn-style-one">
                      <span class="txt">contact us</span>
                    </Link>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Residential;
