import React, { useState } from "react";
import { Link } from "react-router-dom";
import { message } from "antd";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [usermessage, setusermessage] = useState("");
  // const [messageApi, contextHolder] = message.useMessage()
  return (
    <div>
      {/* <!--Page Title--> */}
      {/* {contextHolder} */}
      <section
        className="page-title"
        style={{ backgroundImage: `url(images/background/5.jpg)` }}
      >
        <div className="auto-container">
          <h2>Contact Us</h2>
          <ul className="page-breadcrumb">
            <li>
              <Link to="/">home</Link>
            </li>
            <li>Contact Us</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- Contact Form Section --> */}
      <section
        className="contact-form-section"
        style={{ backgroundImage: `url(images/background/contact.png)` }}
      >
        <div className="auto-container">
          {/* <!-- Sec Title --> */}
          <div className="sec-title">
            <h2>Get In Touch</h2>
            <div className="text">
              Do you have any queries or inquiries? Feel free to reach us
              anytime
            </div>
          </div>

          <div className="row clearfix">
            {/* <!-- Form Column --> */}
            <div className="form-column col-lg-7 col-md-12 col-sm-12">
              <div className="inner-column">
                {/* <!-- Contact Form --> */}
                <div className="contact-form">
                  {/* <!--Contact Form--> */}
                  <form id="contact-form">
                    <div className="row clearfix">
                      <div className="form-group col-lg-6 col-md-6 col-sm-12">
                        <input
                          type="text"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Your name"
                          required
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-6 col-sm-12">
                        <input
                          type="text"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email address"
                          required
                        />
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-sm-12">
                        <input
                          type="text"
                          name="subject"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          placeholder="Subject"
                          required
                        />
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-sm-12">
                        <textarea
                          name="message"
                          value={usermessage}
                          onChange={(e) => setusermessage(e.target.value)}
                          placeholder="Message"
                        ></textarea>
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-sm-12">
                        <button
                          className="theme-btn btn-style-one"
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();

                            if (
                              !name ||
                              !usermessage ||
                              !subject ||
                              !email === ""
                            ) {
                              return message.error(
                                "All the fields are required"
                              );
                            }
                            console.log(name, usermessage, subject, email);
                            message.success(
                              "Thanks for reaching out to Mianzi designs we'll be right back to you"
                            );
                          }}
                          name="submit-form"
                        >
                          <span className="txt">Submit now</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* <!-- Info Column --> */}
            <div className="info-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                {/* <!-- Contact Info List --> */}
                <ul className="contact-info-list">
                  <li>
                    <strong>Address :</strong>
                    <br />
                    Ngong' Road, Nairobi, Kenya
                  </li>
                </ul>
                {/* <!-- Contact Info List --> */}
                <ul className="contact-info-list">
                  {/* <li>
                    <strong>Phone : </strong>
                    <a href="tel:+254725177878">(+254) 725 177 878</a>
                  </li> */}
                  <li>
                    <strong>Email : </strong>
                    <a href="mailto:info@mianzidesignstudio.com">
                      info@mianzidesignstudio.com
                    </a>
                  </li>
                </ul>
                {/* <!-- Contact Info List --> */}
                <ul className="contact-info-list">
                  <li>
                    <strong>Opening Hours :</strong>
                    <br />
                    8:00AM - 5:00PM Monday - Friday
                    <br />
                    9:30AM - 3:00PM Saturday
                    <br />
                    Closed on Sundays
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Contact Form Section --> */}

      {/* <!-- Map Section --> */}
      <section className="map-section">
        <div className="outer-container">
          <div className="map-outer">
            {/* <div
              
              
              data-lng="144.955631"
              data-type="roadmap"
              data-hue="#ffc400"
              
              data-icon-path="images/icons/map-marker.png"
              data-content="(1800) 456 7890 <br> Mon-Sat: 7.00an - 9.00pm"
            ></div> */}
            <iframe
              data-zoom="12"
              data-title="Mianzi"
              data-lat="-37.817085"
              className="map-canvas"
              title="Mianzi designs"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.108877250523!2d36.7374596!3d-1.3089316!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1b7b11959bb9%3A0x3d4ac0b86a48d6a8!2sMianzi%20Designs!5e0!3m2!1sen!2ske!4v1704381649127!5m2!1sen!2ske"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowfullScreen
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
      {/* <!-- End Map Section --> */}
    </div>
  );
}

export default Contact;
