import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        className="page-title"
        style={{ backgroundImage: `url(images/background/5.jpg)` }}
      >
        <div className="auto-container">
          <h2>About us</h2>
          <ul className="page-breadcrumb">
            <li>
              <Link to="/">home</Link>
            </li>
            <li>About us</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- Story Section --> */}
      <section className="story-section">
        <div className="auto-container">
          <div className="row clearfix">
            {/* <!-- Content Column --> */}
            <div className="content-column col-lg-8 col-md-12 col-sm-12">
              <div className="inner-column">
                <h2>About Mianzi Designs</h2>
                <div className="bold-text"></div>
                <div className="text">
                  <p>
                    Mianzi Designs is a Kenyan Start-up that is mainstreaming
                    the use of bamboo in the local construction industry. Our
                    in-house team consists of qualified building design
                    professionals as well as specialized technicians
                    specializing in the area of bamboo construction and
                    manufacturing.
                  </p>
                  <p>
                    As a company we have been engaged on several projects
                    locally where we have installed locally manufactured bamboo
                    flooring. We have also designed several projects that are to
                    be fully implemented with bamboo being the main structural
                    component.
                  </p>
                  <p>
                    We hope to achieve environmental protection through planting
                    more bamboo that can then be consumed in the built
                    environment. It is a known fact that globally, the impact of
                    climate change is biting; our agenda is therefore to
                    mainstream the use of bamboo in construction in Kenya with
                    the hope that more and more people will embrace bamboo
                    farming thereby increasing our green cover which is still
                    below target.
                  </p>
                  <p>
                    We work with a team of young people whom we have empowered
                    through support from our partners. We hope that as we grow,
                    we shall create more employment for young people and women.
                  </p>
                  {/* hgydyfd */}
                  <p>
                    At Mianzi Designs, we believe that every space tells a
                    story. Founded in 2023, our architectural journey is rooted
                    in a passion for crafting spaces that resonate with
                    innovation, elegance, and purpose.
                  </p>
                  <p>
                    We take pride in not only our traditional architectural
                    designs but also our expertise in sustainable bamboo
                    designs.
                  </p>
                </div>
              </div>
            </div>

            {/* <!-- Image Column --> */}
            <div className="image-column col-lg-4 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="image">
                  {/* <img src="images/resource/story.jpg" alt="" /> */}
                  <img
                    src={require("../resources/assets/media/wardrobe.jpg")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Story Section --> */}

      {/* <!-- Interior Section --> */}
      <section className="interior-section style-three">
        <div className="auto-container">
          <div className="inner-container">
            <div className="row clearfix">
              {/* <!-- Image Column --> */}
              <div className="image-column col-lg-4 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="image">
                    {/* <img src="images/resource/interior.jpg" alt="" /> */}
                    <img
                      src={require("../resources/assets/media/livingroom1.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              {/* <!-- Content Column --> */}
              <div className="content-column col-lg-8 col-md-12 col-sm-12">
                <div className="inner-column">
                  <h2>Why Choose Mianzi Designs</h2>
                  <div className="text">
                    <span style={{ color: "#E1B76D" }}>
                      Tailored Solutions:
                    </span>{" "}
                    We understand that each client is unique. Our approach
                    involves crafting bespoke solutions that align with your
                    vision, preferences, and lifestyle.
                    <br />
                    <span style={{ color: "#E1B76D" }}>
                      Client-Centric Focus:
                    </span>{" "}
                    Your satisfaction is our priority. We keep you involved
                    throughout the design and construction process, ensuring
                    transparency, effective communication, and a seamless
                    experience. <br />
                    {/* <span style={{color:"#E1B76D"}}>Proven Track Record:</span> With a portfolio
                    of successful projects spanning 2 years,
                    Mianzi Designs has earned a reputation for delivering
                    exceptional results. Discover the world of Mianzi Designs—a
                    world where architecture becomes an art, bamboo is a canvas,
                    and every space is a masterpiece. */}
                  </div>
                  <div className="row clearfix">
                    {/* <!-- Interior Block --> */}
                    <div className="interior-block col-lg-4 col-md-4 col-sm-12">
                      <div className="block-inner">
                        <div className="icon-box">
                          <span className="icon flaticon-award-1"></span>
                        </div>
                        <h3>Superior Quality</h3>
                      </div>
                    </div>

                    {/* <!-- Interior Block --> */}
                    <div className="interior-block col-lg-4 col-md-4 col-sm-12">
                      <div className="block-inner">
                        <div className="icon-box">
                          <span className="icon flaticon-answer"></span>
                        </div>
                        <h3>Professional Team</h3>
                      </div>
                    </div>

                    {/* <!-- Interior Block --> */}
                    <div className="interior-block col-lg-4 col-md-4 col-sm-12">
                      <div className="block-inner">
                        <div className="icon-box">
                          <span className="icon flaticon-hand"></span>
                        </div>
                        <h3>Unmatched Warranty</h3>
                      </div>
                    </div>
                  </div>

                  <div className="bold-text">
                    Get in touch with us and manifest your dream
                  </div>
                  {/* <div class="column-text">
                    Talk to our design expert and get your designs
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Interior Section --> */}

      {/* <!-- Counter Section --> */}
      <section className="counter-section style-two">
        <div className="auto-container">
          {/* <!-- Fact Counter --> */}
          <div className="fact-counter">
            <div className="row clearfix">
              {/* <!--Column--> */}
              <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  className="inner wow fadeInLeft"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div className="content">
                    <div className="count-outer count-box">
                      <span
                        className="count-text"
                        data-speed="2500"
                        data-stop="8"
                      >
                        4
                      </span>{" "}
                      Years
                    </div>
                    <h4 className="counter-title">Of Service</h4>
                  </div>
                </div>
              </div>

              {/* <!--Column--> */}
              <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  className="inner wow fadeInLeft"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  <div className="content">
                    <div className="count-outer count-box alternate">
                      <span
                        className="count-text"
                        data-speed="3000"
                        data-stop="2500"
                      >
                        10
                      </span>
                      +
                    </div>
                    <h4 className="counter-title">Completed Projects</h4>
                  </div>
                </div>
              </div>

              {/* <!--Column--> */}
              <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  className="inner wow fadeInLeft"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                >
                  <div className="content">
                    <div className="count-outer count-box">
                      <span
                        className="count-text"
                        data-speed="3000"
                        data-stop="200"
                      >
                        20
                      </span>
                      +
                    </div>
                    <h4 className="counter-title">Designers</h4>
                  </div>
                </div>
              </div>

              {/* <!--Column--> */}
              <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  className="inner wow fadeInLeft"
                  data-wow-delay="900ms"
                  data-wow-duration="1500ms"
                >
                  <div className="content">
                    <div className="count-outer count-box">
                      <span
                        className="count-text"
                        data-speed="2500"
                        data-stop="45"
                      >
                        10
                      </span>{" "}
                      Days
                    </div>
                    <h4 className="counter-title">Project Delivery</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Counter Section --> */}

      {/* <!-- Team Section --> */}
      <section className="team-section style-two">
        <div className="auto-container">
          {/* <!-- Sec Title --> */}
          {/* <div className="sec-title light centered">
            <h2>Our Team</h2>
            <div className="text">
              Professional team for all your design needs.
            </div>
          </div> */}

          {/* <div className="clearfix"> */}
          {/* <!-- Team Block --> */}
          {/* <div className="team-block col-lg-3 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <img src="images/resource/team-1.jpg" alt="" />
                  <div className="overlay-box">
                    <ul className="social-icons">
                      <li>
                        <a href="/">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-twitter-square"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-skype"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="lower-content">
                  <h3>
                    <a href="/">Merry Desulva</a>
                  </h3>
                  <div className="designation">Interior Designer</div>
                </div>
              </div>
            </div> */}

          {/* <!-- Team Block --> */}
          {/* <div className="team-block col-lg-3 col-md-6 col-sm-12">
              <div className="inner-box ">
                <div className="image">
                  <img src="images/resource/team-2.jpg" alt="" />
                  <div className="overlay-box">
                    <ul className="social-icons">
                      <li>
                        <a href="/">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-twitter-square"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-skype"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="lower-content">
                  <h3>
                    <a href="team.html">Roseen</a>
                  </h3>
                  <div className="designation">Consultant for Designs</div>
                </div>
              </div>
            </div> */}

          {/* <!-- Team Block --> */}
          {/* <div className="team-block col-lg-3 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <img src="images/resource/team-3.jpg" alt="" />
                  <div className="overlay-box">
                    <ul className="social-icons">
                      <li>
                        <a href="/">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-twitter-square"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-skype"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="lower-content">
                  <h3>
                    <a href="/">Merry Desulva</a>
                  </h3>
                  <div className="designation">Interior Designer</div>
                </div>
              </div>
            </div> */}

          {/* <!-- Team Block --> */}
          {/* <div className="team-block col-lg-3 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <img src="images/resource/team-4.jpg" alt="" />
                  <div className="overlay-box">
                    <ul className="social-icons">
                      <li>
                        <a href="/">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-twitter-square"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-skype"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="lower-content">
                  <h3>
                    <a href="/">Roseen</a>
                  </h3>
                  <div className="designation">Consultant for Designs</div>
                </div>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </section>
      {/* <!-- End Team Section --> */}

      {/* <!-- Mission Section --> */}
      <section className="mission-section style-two">
        <div className="auto-container">
          <div className="row clearfix">
            {/* <!-- Image Column --> */}
            <div className="image-column col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="image">
                  <img
                    src={require("../resources/assets/media/chandeleirhf.jpg")}
                    alt=""
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className="content-column col-xl-9 col-lg-8 col-md-12 col-sm-12">
              <div className="inner-column">
                <h2>Our Mission</h2>
                {/* <div className="bold-text">
                  we believe that interior design is more than great
                  functionality and <br /> beautiful aesthetics.
                </div> */}
                <div className="text">
                  At the core of Mianzi Designs is a mission to transform spaces
                  into meaningful experiences. Our designs go beyond aesthetics;
                  they tell stories, evoke emotions, and create environments
                  that enrich lives.
                </div>
                <h3>Best designers in town</h3>
                <div className="row clearfix">
                  <div className="column col-lg-6 col-md-6 col-sm-12">
                    <ul className="list-style-two">
                      <li>- Best Design Consultancy</li>
                      <li>- Architect Designer</li>
                      <li>- Turnkey Projects</li>
                    </ul>
                  </div>
                  <div className="column col-lg-6 col-md-6 col-sm-12">
                    <ul className="list-style-two">
                      <li>- Residential Designs</li>
                      <li>- Corporate Designs</li>
                      <li>- Commercial Designs</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="auto-container">
          <div className="row clearfix">
            {/* <!-- Content Column --> */}
            <div className="content-column col-xl-9 col-lg-8 col-md-12 col-sm-12">
              <div className="inner-column">
                <h2>Our Vision</h2>
                {/* <div className="bold-text">
                  we believe that interior design is more than great
                  functionality and <br /> beautiful aesthetics.
                </div> */}
                <div className="text">
                  At Mianzi Designs, our vision is to be a global leader in
                  innovative and sustainable architectural design. We aspire to
                  redefine the future of living spaces by seamlessly blending
                  cutting-edge design concepts with eco-friendly practices.
                  Through our commitment to excellence, creativity, and
                  sustainability, we envision a world where Mianzi-designed
                  spaces not only inspire and elevate lives but also contribute
                  to a harmonious and resilient planet. Our vision is to pioneer
                  a new era in architecture, where each creation reflects our
                  dedication to timeless elegance, environmental responsibility,
                  and the enduring pursuit of design perfection.
                </div>
                {/* <h3>Best designers in town</h3>
                <div className="row clearfix">
                  <div className="column col-lg-6 col-md-6 col-sm-12">
                    <ul className="list-style-two">
                      <li>- Best Design Consultancy</li>
                      <li>- Architect Designer</li>
                      <li>- Turnkey Projects</li>
                    </ul>
                  </div>
                  <div className="column col-lg-6 col-md-6 col-sm-12">
                    <ul className="list-style-two">
                      <li>- Residential Designs</li>
                      <li>- Corporate Designs</li>
                      <li>- Commercial Designs</li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <!-- Image Column --> */}
            <div className="image-column col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="image">
                  <img
                    src={require("../resources/assets/media/livingroomqt.jpg")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Core values */}
        <div className="auto-container">
          <div className="row clearfix">
            {/* <!-- Image Column --> */}
            <div className="image-column col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="image">
                  <img
                    src={require("../resources/assets/media/livingroomqt1.jpg")}
                    alt=""
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className="content-column col-xl-9 col-lg-8 col-md-12 col-sm-12">
              <div className="inner-column">
                <h2>Our Values</h2>
                {/* <div className="bold-text">
                  we believe that interior design is more than great
                  functionality and <br /> beautiful aesthetics.
                </div> */}
                <div className="text">
                  <span style={{ color: "rgb(223, 177, 98)" }}>
                    Innovation:
                  </span>{" "}
                  We are committed to pushing the boundaries of architectural
                  design, embracing innovative solutions that reflect the
                  evolving needs of our clients.
                  <br />
                  <span style={{ color: "rgb(223, 177, 98)" }}>
                    Excellence:
                  </span>{" "}
                  Mianzi Designs stands for architectural excellence. Every
                  project we undertake is approached with a dedication to
                  precision, quality, and timeless elegance.
                  <br />
                  <span style={{ color: "rgb(223, 177, 98)" }}>
                    Collaboration:
                  </span>{" "}
                  We believe in the power of collaboration. Working closely with
                  our clients, we transform ideas into reality, ensuring that
                  each design is a collaborative masterpiece.
                  <br />
                  <span style={{ color: "rgb(223, 177, 98)" }}>
                    Sustainability:
                  </span>{" "}
                  Mianzi Designs is committed to sustainable architecture. Our
                  designs not only enhance the lives of the present but also
                  contribute to a sustainable and resilient future.
                </div>

                <div className="row clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        {/* Bamboo design */}
        <div className="content-column col-xl-9 col-lg-8 col-md-12 col-sm-12">
          <div className="inner-column">
            <h2>Expertise in Bamboo Designs</h2>
            {/* <div className="bold-text">
                  we believe that interior design is more than great
                  functionality and <br /> beautiful aesthetics.
                </div> */}
            <div className="text">
              In addition to our conventional architectural designs, Mianzi
              Designs specializes in innovative bamboo designs. Bamboo, with its
              eco-friendly and versatile properties, serves as a cornerstone of
              our commitment to sustainable practices. From bamboo structures to
              interior elements, we seamlessly integrate this natural material
              into our designs, offering a harmonious blend of aesthetics and
              environmental responsibility.
            </div>

            <div className="row clearfix"></div>
          </div>
        </div>
      </section>
      {/* <!-- End Mission Section --> */}

      {/* <!-- Call To Action Section --> */}
      <section
        className="call-to-action-section"
        style={{ backgroundImage: `url(images/background/6.jpg)` }}
      >
        <div className="auto-container">
          <h2>Think Design. Think Mianzi Designs</h2>
          <div className="text">
            Where Imagination Meets Precision, and Every Space Tells a Unique
            Story.
          </div>
          <Link to="/contact" className="theme-btn btn-style-two">
            <span className="txt">contact us</span>
          </Link>
        </div>
      </section>
      {/* <!-- End Call To Action Section --> */}
    </div>
  );
}

export default About;
