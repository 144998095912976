import React from "react";
import { Link } from "react-router-dom";

function OfficeInterior() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{ backgroundImage: `url(images/background/5.jpg)` }}
      >
        <div class="auto-container">
          <h2>Office Interior</h2>
          <ul class="page-breadcrumb">
            <li>
              <Link to="/">home</Link>
            </li>
            <li>Services</li>
            <li>Office Interior</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!--Sidebar Page Container--> */}
      <div class="sidebar-page-container">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Content Side / Services Detail --> */}
            <div class="content-side col-xl-9 col-lg-8 col-md-12 col-sm-12">
              <div class="services-detail">
                <div class="inner-box">
                  <div class="bold-text">
                    Welcome to Mianzi Designs' Office Designs, here, innovation
                    and productivity converge to create workspaces that inspire
                    and elevate your business. Our office designs seamlessly
                    blend functionality with aesthetics, cultivating an
                    environment that reflects your brand identity and enhances
                    the well-being of your team.
                  </div>
                  <div class="text">
                    <p>
                      <strong>Bridging Style and Functionality:</strong>
                      Mianzi Designs specializes in crafting office spaces that
                      bridge style with functionality. Our designs are a fusion
                      of contemporary aesthetics and practical layouts,
                      providing an inspiring backdrop for the modern workplace.
                    </p>
                    <p>
                      <strong>Reflecting Your Brand Identity:</strong>
                      Your office is an extension of your brand. Mianzi Designs
                      ensures that every design element aligns with your brand
                      identity, creating a cohesive and professional atmosphere
                      that leaves a lasting impression on clients, partners, and
                      employees.
                    </p>

                    <p>
                      <strong>Dynamic Work Environments:</strong>
                      Our office designs prioritize dynamic work environments
                      that foster collaboration, creativity, and efficiency.
                      From open workspaces that encourage teamwork to private
                      areas for focused tasks, Mianzi Designs tailors each space
                      to meet the diverse needs of your team.
                    </p>
                    <p>
                      <strong>Employee Well-Being as a Priority:</strong>
                      The well-being of your team is at the forefront of our
                      designs. Mianzi Designs incorporates elements that enhance
                      employee comfort and satisfaction, from ergonomic
                      furniture to thoughtful lighting, creating a workspace
                      that supports both productivity and health.
                    </p>

                    <p>
                      <strong>Optimizing Spatial Efficiency:</strong>
                      Efficiency is key in a modern office. Mianzi Designs
                      optimizes spatial layouts to ensure that every square foot
                      serves a purpose. Our designs maximize functionality
                      without compromising on aesthetics, creating an
                      environment that is both practical and visually appealing.
                    </p>

                    {/* <!-- Two Column --> */}
                    <div class="two-column">
                      <div class="row clearfix">
                        <div class="column col-lg-4 col-md-4 col-sm-12">
                          <div class="image">
                            <img
                              src={require("../resources/assets/media/project4hf.jpg")}
                              alt=""
                            />
                          </div>
                          <div class="image">
                            <img src="images/resource/service-23.jpg" alt="" />
                          </div>
                        </div>
                        <div class="content-column col-lg-8 col-md-8 col-sm-12">
                          <div class="inner-column">
                            <h3>Collaborate for a Unique Workspace:</h3>
                            <p>
                              Mianzi Designs invites you to collaborate in
                              designing a workspace that is uniquely yours.
                              Whether you're envisioning a sleek corporate
                              office or a creative hub, our team is ready to
                              turn your vision into a reality.
                            </p>
                            <p>
                              <strong>Discover Our Office Portfolio:</strong>
                              Explore our diverse portfolio of office designs to
                              see the transformative power of our creations.
                              Each project showcases our ability to create
                              spaces that align with the unique culture and
                              aspirations of each business.
                            </p>
                            <p>
                              <strong>Incorporating Modern Technology:</strong>
                              Mianzi Designs stays at the cutting edge of design
                              and technology. Our office designs incorporate
                              modern tech solutions, from smart office features
                              to integrated communication systems, keeping your
                              workspace connected and efficient.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="service-contact-box">
                      Our experienced Designers are ready for you.
                      <br /> <Link to="/contact">Contact us</Link> to learn
                      more!
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--Sidebar Side--> */}
            <div class="sidebar-side col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <aside class="sidebar">
                {/* <!-- Sidebar Widget / List Widget--> */}
                <div class="sidebar-widget list-widget">
                  {/* <!-- Services List --> */}
                  <ul class="services-list">
                    <li>
                      <Link to="/residential">Residential Design</Link>
                    </li>
                    <li>
                      <Link to="/commercial-interior">Commercial Design</Link>
                    </li>
                    <li class="active">
                      <Link to="/office-design">Office Designs</Link>
                    </li>
                    <li>
                      <Link to="/hospitality-design">Hospitality Design</Link>
                    </li>
                    <li>
                      <a href="/modern-furniture">Modern Furniture</a>
                    </li>
                    <li>
                      <Link to="/kitchen-designs">Modular Kitchen</Link>
                    </li>
                    <li>
                      <Link to="/wardrobe">Wardrobe</Link>
                    </li>
                    <li>
                      <a href="/ceiling-design">Ceiling Designs</a>
                    </li>
                  </ul>
                </div>

                {/* <!-- Sidebar Widget / Contact Widget--> */}
                <div class="sidebar-widget contact-widget">
                  <div
                    class="widget-content"
                    style={{ backgroundImage: `url(images/background/7.jpg)` }}
                  >
                    <h3>Get Free Quote</h3>
                    <Link to="/contact" class="theme-btn btn-style-one">
                      <span class="txt">contact us</span>
                    </Link>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfficeInterior;
