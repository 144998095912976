import React from "react";
import { Link } from "react-router-dom";
import project1 from "../resources/assets/projects/Rescuecesnter1.jpg";
import project2 from "../resources/assets/projects/Rongai renovation.jpg";
import project3 from "../resources/assets/projects/emergency.jpg";
import bedroom from "../resources/assets/projects/bedroomdesign.jpg";
import ekegondihse from "../resources/assets/projects/ekegondihouse.jpg";
import evacksm from "../resources/assets/projects/evacuationcenterksm.jpg";
import interiorsp from "../resources/assets/projects/interiorspace.jpg";
import livindsn from "../resources/assets/projects/livinroomdesign.jpg";
import officeblkkrn from "../resources/assets/projects/officeblockkaren.jpg";
import officekrn from "../resources/assets/projects/officeinkaren.jpg";
import officelambwe from "../resources/assets/projects/officelambwevalley.jpg";
import res1 from "../resources/assets/projects/residential.jpg";
import res2 from "../resources/assets/projects/residential1.jpg";
import resrongai from "../resources/assets/projects/residentialrongai.jpg";
import resksj from "../resources/assets/projects/resinkisaju.jpg";
import resksj2 from "../resources/assets/projects/resinkisaju1.jpg";
import resksj3 from "../resources/assets/projects/resinkisaju2.jpg";
import project18 from "../resources/assets/projects/IMG-20240218-WA0076.jpg";

function Projects() {
  const projects = [
    {
      src: project1,
      name: "Rescue Center",
    },
    {
      src: project2,
      name: "Rongai renovation",
    },
    {
      src: project3,
      name: "Emergency Center",
    },
    {
      src: bedroom,
      name: "Bedroom design",
    },
    {
      src: ekegondihse,
      name: "Ekegondi House",
    },
    {
      src: evacksm,
      name: "Proposed emergency evac center Kisumu",
    },
    {
      src: interiorsp,
      name: "Interior Corridors",
    },
    {
      src: livindsn,
      name: "Living Room Design",
    },
    {
      src: officeblkkrn,
      name: "Proposed Office Block Karen",
    },
    {
      src: officekrn,
      name: "Proposed Office Block Karen",
    },
    {
      src: officelambwe,
      name: "Proposed Office in Lambwe Valley",
    },
    {
      src: res1,
      name: "Residential Design",
    },
    {
      src: res2,
      name: "Residential Design",
    },
    {
      src: resrongai,
      name: "Proposed Residential house Rongai",
    },
    {
      src: resksj,
      name: "Proposed Residential house Kisaju",
    },
    {
      src: resksj2,
      name: "Proposed Residential house Kisaju",
    },
    {
      src: resksj3,
      name: "Proposed Residential house Kisaju",
    },
    {
      src: project18,
      name: "Proposed Residential house in Rimpa",
    },
  ];
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{ backgroundImage: `url(images/background/5.jpg)` }}
      >
        <div class="auto-container">
          <h2>Projects</h2>
          <ul class="page-breadcrumb">
            <li>
              <Link to="/">home</Link>
            </li>
            <li>Projects</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}
      {/* <!-- Portfolio Page Section --> */}
      <section className="project-section">
        <div className="auto-container">
          {/* <!-- Title Box --> */}
          <div className="title-box">
            <h2>Explore Projects</h2>
          </div>
        </div>

        <div className="outer-container">
          {/* <!--Isotope Galery--> */}
          <div className="sortable-masonry">
            {/* <!--Filter--> */}
            <div className="filters clearfix">
              <ul className="filter-tabs filter-btns text-center clearfix">
                <li
                  className="active filter"
                  data-role="button"
                  data-filter=".all"
                >
                  All Projects
                </li>
              </ul>
            </div>

            <div className="items-container row clearfix">
              {/* Dynamic */}

              {projects.map((p, index) => (
                <div className="gallery-item small-block masonry-item all hospital commercial">
                  <div className="inner-box">
                    <figure className="image-box">
                      <img src={p.src} alt="" />
                      {/* <!--Overlay Box--> */}
                      <div className="overlay-box">
                        <div className="overlay-inner">
                          <div className="content">
                            <h3>
                              <Link to="projects-fullwidth">{p.name}</Link>
                            </h3>
                            <a
                              href={p.src}
                              data-fancybox="gallery-1"
                              data-caption=""
                              className="link"
                            >
                              <span className="icon flaticon-magnifying-glass-1"></span>
                            </a>
                            <a href={p.src} className="link">
                              <span className="icon flaticon-unlink"></span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </figure>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Story Section --> */}
    </div>
  );
}

export default Projects;
