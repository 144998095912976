import React from "react";
import { Link } from "react-router-dom";

function Services() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        className="page-title"
        style={{ backgroundImage: `url(images/background/5.jpg)` }}
      >
        <div className="auto-container">
          <h2>Services</h2>
          <ul className="page-breadcrumb">
            <li>
              <Link to="/">home</Link>
            </li>
            <li>Services</li>
          </ul>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- Services Page Section --> */}
      <section className="services-page-section">
        <div className="auto-container">
          {/* <!-- Sec Title --> */}
          <div className="sec-title light centered">
            <h2>We Provide Different Design Services</h2>
            <div className="text">
              Transform Your Space with Mianzi Designs: Elevating Every Detail
              to Craft Exceptional Environments Tailored to Your Unique Vision
              and Lifestyle
            </div>
          </div>

          <div className="row clearfix">
            {/* <!-- Service Block --> */}
            <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <Link to="/residential">
                    <img src="images/resource/service-8.jpg" alt="" />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to="/residential">Residential Design</Link>
                  </h3>
                  <div className="text">
                    Make your dream home a reality with Mianzi's residential
                    designs. We specialize in creating personalized living
                    spaces that reflect your lifestyle and aspirations.
                  </div>
                  <Link to="/residential" className="read-more">
                    Read more
                  </Link>
                </div>
              </div>
            </div>

            {/* <!-- Service Block --> */}
            <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-delay="250ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <Link to="/commercial-interior">
                    <img src="images/resource/service-9.jpg" alt="" />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to="/commercial-interior">Commercial Designs</Link>
                  </h3>
                  <div className="text">
                    Mianzi redefines commercial spaces with designs that reflect
                    your brand identity. Our commercial designs merge aesthetics
                    with practicality to create dynamic and inspiring business
                    environments.
                  </div>
                  <Link to="/commercial-interior" className="read-more">
                    Read more
                  </Link>
                </div>
              </div>
            </div>

            {/* <!-- Service Block --> */}
            {/* <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-delay="500ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <Link to="/office-design">
                    <img src="images/resource/service-10.jpg" alt="" />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to="/office-design">Office Design</Link>
                  </h3>
                  <div className="text">
                    Elevate your workspace with Mianzi's visionary office
                    designs. We seamlessly blend functionality with aesthetics,
                    creating environments that inspire productivity and
                    innovation.
                  </div>
                  <Link to="/office-design" className="read-more">
                    Read more
                  </Link>
                </div>
              </div>
            </div> */}

            {/* <!-- Service Block --> */}
            {/* <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <Link to="/hospitality-design">
                    <img src="images/resource/service-11.jpg" alt="" />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to="/hospitality-design">Hospitality Design</Link>
                  </h3>
                  <div className="text">
                    Experience the art of hospitality through Mianzi's designs.
                    Our hospitality designs create immersive guest experiences,
                    balancing luxury and functionality.
                  </div>
                  <Link to="/hospitality-design" className="read-more">
                    Read more
                  </Link>
                </div>
              </div>
            </div> */}

            {/* <!-- Service Block --> */}
            {/* <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-delay="250ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <Link to="/modern-furniture">
                    <img src="images/resource/service-12.jpg" alt="" />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to="/modern-furniture">Modern Furniture</Link>
                  </h3>
                  <div className="text">
                    Modern furniture design blends simplicity, functionality,
                    and aesthetic innovation, featuring clean lines,
                    contemporary materials, and a focus on practicality for a
                    timeless and sophisticated look.
                  </div>
                  <Link to="/modern-furniture" className="read-more">
                    Read more
                  </Link>
                </div>
              </div>
            </div> */}

            {/* <!-- Service Block --> */}
            <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-delay="500ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <a href="/modern-kitchen">
                    <img src="images/resource/service-13.jpg" alt="" />
                  </a>
                </div>
                <div className="lower-content">
                  <h3>
                    <a href="/modern-kitchen">Modern Kitchen</a>
                  </h3>
                  <div className="text">
                    Redefine your culinary space with Mianzi's modern kitchen
                    designs. Our designs balance form and function, creating
                    kitchens that are both aesthetically pleasing and highly
                    efficient.
                  </div>
                  <a href="/modern-kitchen" className="read-more">
                    Read more
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- Service Block --> */}
            {/* <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <Link to="/wardrobe">
                    <img src="images/resource/service-14.jpg" alt="" />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to="/wardrobe">Wardrobe</Link>
                  </h3>
                  <div className="text">
                    Elevate your personal space with Mianzi's wardrobe designs.
                    Our bespoke solutions combine functionality with elegance,
                    offering storage solutions that are both practical and
                    stylish.
                  </div>
                  <Link to="/wardrobe" className="read-more">
                    Read more
                  </Link>
                </div>
              </div>
            </div> */}

            {/* <!-- Service Block --> */}
            {/* <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-delay="250ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <Link to="/ceiling-design">
                    <img src="images/resource/service-15.jpg" alt="" />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to="/ceiling-design">Ceiling Designs</Link>
                  </h3>
                  <div className="text">
                    Transform your space from the top down with Mianzi's ceiling
                    designs. Our innovative concepts redefine the fifth wall,
                    adding character and dimension to every room.
                  </div>
                  <Link to="/ceiling-design" className="read-more">
                    Read more
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* <!-- End Story Section --> */}
    </div>
  );
}

export default Services;
