import { Link, useRouteError } from "react-router-dom";
import "../index.css";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      className="h-screen bg-[#DFB162]/50 flex flex-col text-center justify-center items-center"
      style={{
        height: "100vh",
        backgroundColor: "#282D31ab",
        // paddingTop: "10rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#ffffffab",
      }}
    >
      <h1
        className="font-bold text-lg text-gray-800"
        style={{ color: "#ffffffab" }}
      >
        Oops!
      </h1>
      <p className="italic" style={{ color: "#ffffffac" }}>
        Sorry, an unexpected error has occurred.
      </p>
      <p className="text-lg" style={{ color: "#ffffffac" }}>
        <i>{error.statusText || error.message}</i>
      </p>
      <div className="p-4 text-gray-900 font-bold cursor-pointer mt-8">
        <Link
          className="flex italic w-fit h-fit px-3 py-1 border border-pink-700 rounded justify-center items-center"
          style={{ color: "#fff" }}
          to="/"
        >
          Home
        </Link>
      </div>
    </div>
  );
}
