import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer className="main-footer">
        <div className="auto-container">
          {/* <!--Widgets Section--> */}
          <div className="widgets-section">
            <div className="row clearfix">
              {/* <!--big column--> */}
              <div className="big-column col-lg-6 col-md-12 col-sm-12">
                <div className="row clearfix">
                  {/* <!--Footer Column--> */}
                  <div className="footer-column col-lg-7 col-md-6 col-sm-12">
                    <div className="footer-widget logo-widget">
                      <Link to={"/about"}>
                        <div className="logo">
                          <p
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              fontWeight: "semibold",
                            }}
                          >
                            Mianzi{" "}
                            <span style={{ color: "#dfb162" }}>Designs</span>
                          </p>
                        </div>
                        <div className="text">
                          Mianzi Designs Studio is your one stop for the best
                          design. Imaginative and creative team to take care of
                          your aspirations
                        </div>
                      </Link>
                      <ul className="social-icons">
                        <li>
                          <a
                            href="https://m.facebook.com/people/Mianzi-Designs/100067205023809/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/in/mianzi-designs-707a20230/?originalSubdomain=ke"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="fab fa-linkedin"></span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/MianziD"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/mianzi_global/?hl=en"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="fab fa-instagram"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* <!--Footer Column--> */}
                  <div className="footer-column col-lg-5 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h2>Quick links</h2>
                      <div className="widget-content">
                        <ul className="list">
                          <li>
                            <Link to="/about">About Mianzi Designs</Link>
                          </li>
                          <li>
                            <Link to="/privacy">Privacy Policy</Link>
                          </li>
                          <li>
                            <Link to="/terms-conditions">Terms of service</Link>
                          </li>
                          <li>
                            <Link to="/frequently-asked">Faq</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--big column--> */}
              <div className="big-column col-lg-6 col-md-12 col-sm-12">
                <div className="row clearfix">
                  {/* <!--Footer Column--> */}
                  <div className="footer-column col-lg-5 col-md-6 col-sm-12">
                    <div className="footer-widget contact-widget">
                      <h2>Contact Info</h2>
                      <div className="widget-content">
                        <a href="tel:+254725177878" className="contact-number">
                          {/* (+254) 725 177 878 */}
                        </a>
                        <ul>
                          <li>Ngong' Road, Nairobi, Kenya</li>
                          <li>
                            Email :
                            <a href="mailto:info@mianzidesignstudio.com">
                              info@mianzidesignstudio.com
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* <!--Footer Column--> */}
                  <div className="footer-column col-lg-7 col-md-6 col-sm-12">
                    <div className="footer-widget newsletter-widget">
                      <h2>Newsletter</h2>
                      <div className="text">Get Special offers & Discounts</div>
                      {/* <!-- Newsletter Form --> */}
                      <div className="newsletter-form">
                        <form method="post" action="">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              placeholder="Enter your email address"
                              required
                            />
                            <button
                              type="submit"
                              className="theme-btn btn-style-one bg-red-400"
                              // style={{ backgroundColor: "white" }}
                            >
                              <span className="txt">Subscribe</span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!--Footer Bottom--> */}
          <div className="footer-bottom clearfix">
            <div className="pull-left">
              <div className="copyright">
                <Link to="https://www.mianzidesignstudio.com">
                  &copy;{new Date().getFullYear()} Mianzi Designs
                </Link>
              </div>
            </div>
            <div className="pull-right">
              <Link to="/">www.mianzidesignstudio.com</Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
